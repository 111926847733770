import api from "/src/api/api";
import * as mutypes from "/src/store/mutation.types";
import async from "async";
import {
    SET_IS_HASH_TOURNAMENT,
    SET_TOURNAMENT_META_DESCRIPTION,
} from "/src/store/mutation.types";

export default {
  state: () => {
    return {
      tournament: {
        games: null,
        game_id: null,
        organizers: null,
        organizer_id: null,
        name: "",
        description: {
          ru: "",
          en: "",
          uz: "",
          kz: "",
        },
        rules: {
          ru: "",
          en: "",
          uz: "",
        },
        prize_fond: "",
        slot_count: "",
        formats: [],
        features: null,
        timezone: null,
        metaTitle: {
          ru: "",
          en: "",
          uz: "",
          kz: "",
        },
        metaDescription: {
          ru: "",
          en: "",
          uz: "",
          kz: "",
        },
      },
      organizers: null,
      games: null,
      currentFormats: null,
      currentStatus: null,
      start_reg_time: null,
      end_reg_time: null,
      start_time: null,
      schedule: {
        ru: "",
        en: "",
        uz: "",
      },
      currentCountry: null,
      currentImage: null,
      currentMobileImage: null,
      currentFeature: [],
      currentYoutubeLink: null,
      currentTournamentSlug: null,
      tournDivisions: [],
      currentDivisions: [],
      currentRelatedTournaments: null,
      tournamentPlaces: [],
      tournamentPrizes: [],
      variations: [],
      with_comments: false,
      with_cap_chats: false,
    };
  },
  mutations: {
    [mutypes.SET_TOURNAMENT]: (state, value) => (state.tournament = value),
    [mutypes.SET_TOURNAMENT_GAMES]: (state, value) => (state.games = value),
    [mutypes.SET_TOURNAMENT_GAME_ID]: (state, value) =>
      (state.tournament.game_id = value),
    [mutypes.SET_TOURNAMENT_ORGANIZERS]: (state, value) =>
      (state.organizers = value),
    [mutypes.SET_TOURNAMENT_ORGANIZER_ID]: (state, value) =>
      (state.tournament.organizer_id = value),
    [mutypes.SET_TOURNAMENT_END_REG_TIME]: (state, value) =>
      (state.end_reg_time = value),
    [mutypes.SET_TOURNAMENT_REG_TIME]: (state, value) =>
      (state.start_reg_time = value),
    [mutypes.SET_TOURNAMENT_START_TIME]: (state, value) =>
      (state.start_time = value),
    [mutypes.SET_TOURNAMENT_NAME]: (state, value) =>
      (state.tournament.name = value),
    [mutypes.SET_TOURNAMENT_DESCRIPTION]: (state, value) =>
      (state.tournament.description = value),
    [mutypes.SET_TOURNAMENT_RULES]: (state, value) =>
      (state.tournament.rules = value),
    [mutypes.SET_TOURNAMENT_PRIZE_FOND]: (state, value) =>
      (state.tournament.prize_fond = value),
    [mutypes.SET_TOURNAMENT_SLOT_COUNT]: (state, value) =>
      (state.tournament.slot_count = value),
    [mutypes.SET_TOURNAMENT_FORMATS]: (state, value) =>
      (state.tournament.formats = value),
    [mutypes.SET_TOURNAMENT_TIMEZONE]: (state, value) =>
      (state.tournament.timezone = value),
    [mutypes.SET_TOURNAMENT_CURRENT_FORMATS]: (state, value) =>
      (state.currentFormats = value),
    [mutypes.SET_TOURNAMENT_CURRENT_STATUS]: (state, value) =>
      (state.currentStatus = value),
    [mutypes.SET_TOURNAMENT_CURRENT_COUNTRY]: (state, value) =>
      (state.currentCountry = value),
    [mutypes.SET_TOURNAMENT_CURRENT_IMAGE]: (state, value) =>
      (state.currentImage = value),
    [mutypes.SET_TOURNAMENT_CURRENT_IMAGE]: (state, value) =>
        (state.currentMobileImage = value),
    [mutypes.SET_TOURNAMENT_SCHEDULE]: (state, value) =>
      (state.schedule = value),
    [mutypes.SET_TOURNAMENT_CURRENT_FEATURE]: (state, value) =>
      (state.currentFeature = value),
    [mutypes.SET_TOURNAMENT_CURRENT_YOUTUBE_LINK]: (state, value) =>
      (state.currentYoutubeLink = value),
    [mutypes.SET_TOURNAMENT_CURRENT_SLUG]: (state, value) =>
      (state.currentTournamentSlug = value),
    [mutypes.SET_TOURNAMENT_DIVISIONS]: (state, value) =>
      (state.tournDivisions = value),
    [mutypes.SET_TOURNAMENT_CURRENT_DIVISIONS]: (state, value) =>
      (state.currentDivisions = value),
    [mutypes.SET_TOURNAMENT_CURRENT_RELATED_TOURNAMENTS]: (state, value) =>
      (state.currentRelatedTournaments = value),
    [mutypes.SET_TOURNAMENT_PLACES]: (state, value) =>
      (state.tournamentPlaces = value),
    [mutypes.SET_TOURNAMENT_PLACES]: (state, value) =>
      (state.tournamentPlaces = value),
    [mutypes.SET_TOURNAMENT_PRIZES]: (state, value) =>
      (state.tournamentPrizes = value),
    [mutypes.SET_TOURNAMENT_META_TITLE]: (state, value) =>
      (state.tournament.metaTitle = value),
    [mutypes.SET_TOURNAMENT_META_DESCRIPTION]: (state, value) =>
      (state.tournament.metaDescription = value),
    [mutypes.SET_TOURNAMENT_VARIATIONS]: (state, variations) =>
      (state.variations = variations),
    [mutypes.SET_WITH_COMMENTS]: (state, value) =>
      (state.with_comments = value),
    [mutypes.SET_WITH_CAP_CHATS]: (state, value) =>
        (state.with_cap_chats = value),
  },

  getters: {
    getTournament: (state) => state.tournament,
    getTournamentGames: (state) => state.games,
    getTournamentGameId: (state) => state.tournament.game_id,
    getTournamentOrganizers: (state) => state.organizers,
    getTournamentOrganizerId: (state) => state.tournament.organizer_id,
    getTournamentEndRegTime: (state) => state.end_reg_time,
    getTournamentStartRegTime: (state) => state.start_reg_time,
    getTournamentStartTime: (state) => state.start_time,
    getTournamentName: (state) => state.tournament.name,
    getTournamentDescription: (state) => state.tournament.description,
    getTournamentRules: (state) => state.tournament.rules,
    getTournamentPrize: (state) => state.tournament.prize_fond,
    getTournamentSlotCount: (state) => state.tournament.slot_count,
    getTournamentFormats: (state) => state.tournament.formats,
    getTournamentTimezone: (state) => state.tournament.timezone,
    getTournamentCurrentFormats: (state) => state.currentFormats,
    getTournamentCurrentStatus: (state) => state.currentStatus,
    getTournamentSchedule: (state) => state.schedule,
    getTournamentCurrentCountry: (state) => state.currentCountry,
    getTournamentCurrentImage: (state) => state.currentImage,
    getTournamentCurrentMobileImage: (state) => state.currentMobileImage,
    getTournamentCurrentFeature: (state) => state.currentFeature,
    getTournamentCurrentYoutubeLink: (state) => state.currentYoutubeLink,
    getTournamentCurrentSlug: (state) => state.currentTournamentSlug,
    getTournamentDivisions: (state) => state.tournDivisions,
    getTournamentCurrentDivisions: (state) => state.currentDivisions,
    getTournamentCurrentRelatedTournaments: (state) =>
      state.currentRelatedTournaments,
    getTournamentPlaces: (state) => state.tournamentPlaces,
    getTournamentPrizes: (state) => state.tournamentPrizes,
    getTournamentMetaTitle: (state) => state.tournament.metaTitle,
    getTournamentMetaDescription: (state) => state.tournament.metaDescription,
    getTournamentVariations: (state) => state.variations,
    getWithComments: (state) => state.with_comments,
    getWithCapChats: (state) => state.with_cap_chats,
  },

  actions: {
    addTournamentPlace({ commit, getters }) {
      const tournamentPlaces = getters.getTournamentPlaces
        ? getters.getTournamentPlaces
        : [];
      const lastPlace = tournamentPlaces.slice(-1)[0];
      const lastId = lastPlace?.id || 0;
      let latestPlace = lastPlace?.place !== undefined ? lastPlace?.place : -1;

      tournamentPlaces.push({
        id: lastId + 1,
        place: latestPlace + 1,
        place_pts: 0,
      });

      commit(mutypes.SET_TOURNAMENT_PLACES, tournamentPlaces);
    },
    changeTournamentPlace({ commit, getters }, placeParams) {
      let tournamentPlaces = getters.getTournamentPlaces;

      tournamentPlaces = tournamentPlaces.map((placeItem) => {
        if (placeItem.place === placeParams.place) {
          placeItem.place_pts = placeParams.place_pts;
        }
        return placeItem;
      });
      commit(mutypes.SET_TOURNAMENT_PLACES, tournamentPlaces);
    },
    addTournamentPrize({ commit, getters }) {
            
      const tournamentPrizes = getters.getTournamentPrizes;

      if (tournamentPrizes.length) {
        const lastPrize = tournamentPrizes.slice(-1)[0];

        tournamentPrizes.push({
          id: lastPrize.id + 1,
          place: lastPrize.place + 1,
          prize_data: {
            amount: 0,
          },
        });

        commit(mutypes.SET_TOURNAMENT_PRIZES, tournamentPrizes);
      }
    },

    changeTournamentPrize({ commit, getters }, prizeParams) {
      
      let tournamentPrizes = getters.getTournamentPrizes;

      tournamentPrizes = tournamentPrizes.map((prizeItem) => {
        if (prizeItem.id === prizeParams.id) {
          return {
            ...prizeItem,
            prize_data: {
              ...prizeItem.prize_data,
              ...prizeParams.prize_data,
            },
          };
        }
        return prizeItem;
      });
      commit(mutypes.SET_TOURNAMENT_PRIZES, tournamentPrizes);
      
    },

    storeTournament: async ({ commit, getters }, params) => {
      await api.tournament.store(params);
    },
    grabTournament: async ({ commit, getters }, id) => {
      let response = await api.tournament.grabTournament(id);
        
      let transformTournIdsArray =
        response.data.tournament.related_tournaments.map(
          (tournament) => tournament.id
        );

      commit(mutypes.SET_TOURNAMENT, response.data.tournament);

      if (response.data.tournament.prize_fund.length) {
        
        commit(mutypes.SET_TOURNAMENT_PRIZES, response.data.tournament.prize_fund)
      } else  {
        commit(mutypes.SET_TOURNAMENT_PRIZES, [
          {
            id: 1,
            place: 1,
            prize_data: {
              dollars: 0,
            },
          },
        ])
      }
      
      commit(mutypes.SET_TOURNAMENT_GAME_ID, response.data.tournament.game_id);
      commit(
        mutypes.SET_TOURNAMENT_REG_TIME,
        response.data.tournament.start_reg_time
      );
      commit(
        mutypes.SET_TOURNAMENT_END_REG_TIME,
        response.data.tournament.end_reg_time
      );
      commit(
        mutypes.SET_TOURNAMENT_PRIZE_FOND,
        response.data.tournament.prize_fond
      );
      commit(
        mutypes.SET_TOURNAMENT_SLOT_COUNT,
        response.data.tournament.slot_count
      );
      commit(
        mutypes.SET_TOURNAMENT_TIMEZONE,
        response.data.tournament.timezone
      );
      commit(
        mutypes.SET_TOURNAMENT_START_TIME,
        response.data.tournament.start_time
      );
      commit(
        mutypes.SET_TOURNAMENT_SCHEDULE,
        response.data.tournament.info.schedule
      );
      commit(
        mutypes.SET_TOURNAMENT_DESCRIPTION,
        response.data.tournament.info.description
      );
      commit(mutypes.SET_TOURNAMENT_RULES, response.data.tournament.info.rules);
      commit(
        mutypes.SET_TOURNAMENT_CURRENT_COUNTRY,
        response.data.tournament?.country?.id
      );
      commit(
        mutypes.SET_TOURNAMENT_CURRENT_IMAGE,
        response.data.tournament?.logo?.original_url
      );
      commit(
          mutypes.SET_TOURNAMENT_CURRENT_IMAGE,
          response.data.tournament?.mobile_logo?.original_url
      );
      commit(
        mutypes.SET_TOURNAMENT_CURRENT_YOUTUBE_LINK,
        response.data.tournament.youtube_link
      );
      commit(
        mutypes.SET_TOURNAMENT_CURRENT_SLUG,
        response.data.tournament.slug
      );
      commit(
        mutypes.SET_TOURNAMENT_CURRENT_RELATED_TOURNAMENTS,
        transformTournIdsArray.join(", ")
      );
      commit(
        mutypes.SET_TOURNAMENT_PLACES,
        response.data.tournament?.tournament_place_points?.fields
      );
      commit(
        mutypes.SET_TOURNAMENT_ORGANIZER_ID,
        response.data.tournament.organizer.id
      );
      commit(
        mutypes.SET_TOURNAMENT_META_TITLE,
        response.data.tournament.info.meta_title
      );
      commit(
        mutypes.SET_TOURNAMENT_META_DESCRIPTION,
        response.data.tournament.info.meta_description
      );
      commit(
        mutypes.SET_IS_HASH_TOURNAMENT,
        response.data.tournament.hash ? true : false
      );
      commit(
        mutypes.SET_WITH_COMMENTS,
        response.data.tournament.with_comments ? true : false
      );

      commit(
          mutypes.SET_WITH_CAP_CHATS,
          response.data.tournament.with_cap_chats ? true : false
      );

      let statusValues = {};
      const statusId = response.data.tournament.status.id;
      const statusCode = response.data.tournament.status.code;
      statusValues = {
        value: statusId,
        text: statusCode,
      };
      commit(mutypes.SET_TOURNAMENT_CURRENT_STATUS, statusValues);

      let value = {};
      value = {
        value: response.data.tournament.format.id,
        text: response.data.tournament.format.name,
      };
      commit(mutypes.SET_TOURNAMENT_CURRENT_FORMATS, value);

      let divisions = [];
      response.data.tournament.divisions.forEach((el, index) => {
        divisions[index] = {
          value: el.id,
          text: el.name.ru,
        };
      });
      commit(mutypes.SET_TOURNAMENT_CURRENT_DIVISIONS, divisions);

      if (response.data?.tournament?.features) {
        const valuesFeatures = response.data.tournament.features.map((el) => ({
          value: el.id,
          text: el.slug,
        }));
        commit(mutypes.SET_TOURNAMENT_CURRENT_FEATURE, valuesFeatures);
      }

    },
    updateTournament: async ({ dispatch, commit, getters }, params) => {
      const formData = new FormData();
      formData.append("_method", "put");
      // formData.append("game_id", getters.getTournamentGameId);
      formData.append("hash", params.isHashTournament ? 1 : 0);
      formData.append("with_comments", getters.getWithComments ? 1 : 0);
      formData.append("with_cap_chats", getters.getWithCapChats ? 1 : 0);
      formData.append("organizer_id", getters.getTournamentOrganizerId);
      formData.append("end_reg_time", getters.getTournamentEndRegTime);
      formData.append("start_reg_time", getters.getTournamentStartRegTime);
      formData.append("start_time", getters.getTournamentStartTime);
      formData.append("name", getters.getTournamentName);
      for (const [key, value] of Object.entries(
        getters.getTournamentDescription
      )) {
        formData.append(`description[${key}]`, value);
      }
      for (const [key, value] of Object.entries(getters.getTournamentRules)) {
        formData.append(`rules[${key}]`, value);
      }
      for (const [key, value] of Object.entries(
        getters.getTournamentSchedule
      )) {
        formData.append(`schedule[${key}]`, value);
      }
      formData.append("prize_fond", getters.getTournamentPrize);
      // formData.append("format_id", params.formats);
      formData.append("timezone", getters.getTournamentTimezone);
      // formData.append("status", getters.getTournamentCurrentStatus.value);
      formData.append("slot_count", getters.getTournamentSlotCount);
      formData.append("slug", getters.getTournamentCurrentSlug);
      if (getters.getTournamentCurrentCountry !== undefined) {
        formData.append("country_id", getters.getTournamentCurrentCountry);
      }

      // метаданные
      for (const [key, value] of Object.entries(
        getters.getTournamentMetaTitle
      )) {
        formData.append(`meta_title[${key}]`, value);
      }

      for (const [key, value] of Object.entries(
        getters.getTournamentMetaDescription
      )) {
        formData.append(`meta_description[${key}]`, value);
      }

      if (getters.getTournamentCurrentFeature.length > 0) {
        Object.entries(params.features).forEach(([key, value]) => {
          formData.append(`features_ids[]`, value);
        });
      }
      if (params.logo) {
        formData.append("logo", params.logo);
      }
      if (params.mobile_logo) {
        formData.append("mobile_logo", params.mobile_logo);
      }
      if (getters.getTournamentCurrentYoutubeLink !== undefined) {
        formData.append(
          "youtube_link",
          getters.getTournamentCurrentYoutubeLink
        );
      }
      for (const [key, value] of Object.entries(params.divisions)) {
        formData.append(`divisions[]`, value);
      }
      if (getters.getTournamentCurrentRelatedTournaments) {
        const relatedTournaments =
          getters.getTournamentCurrentRelatedTournaments.replaceAll(" ", "");
        for (const [key, value] of Object.entries(
          relatedTournaments.split(",")
        )) {
          formData.append("related_tournament_ids[]", value);
        }
      }
      if (getters.getTournamentPlaces.length) {
        formData.append(
          "tournament_place_points",
          JSON.stringify(getters.getTournamentPlaces)
        );
      }
      if (getters.getTournamentPrizes.length) {
        const prizeFund = getters.getTournamentPrizes.map(prize => {
          const { id, created_at, ...cleanedPrize } = prize; // Убираем ненужные поля
          return cleanedPrize;
        });

        prizeFund.forEach((prize, index) => {
          formData.append(`prize_fund[${index}][place]`, prize.place);
          formData.append(`prize_fund[${index}][prize_data][type]`, 'dollars');

          if (prize.tournament_id) {
            formData.append(`prize_fund[${index}][tournament_id]`, prize.tournament_id);
          }
          if (prize.prize_data) {
            for (const [key, value] of Object.entries(prize.prize_data)) {
              formData.append(`prize_fund[${index}][prize_data][${key}]`, value ?? '');
            }
          }
        });
      }

      await api.tournament.update(params.id, formData);
    },
    grabGames: async ({ commit, getters }) => {
      await api.games
        .index()
        .then((res) => {
          let values = [];
          res.data.games.forEach((item, index) => {
            values[index] = {
              value: item.id,
              text: item.name,
            };
          });

          commit(mutypes.SET_TOURNAMENT_GAMES, values);
          localStorage.setItem("games", JSON.stringify(values));
        })
        .catch();
    },
    grabOrganizers: async ({ commit, getters }) => {
      let response = await api.organizers.index();
      let values = [];
      response.data.organizers.forEach((item, index) => {
        values[index] = {
          value: item.id,
          text: item.name,
        };
      });
      commit(mutypes.SET_TOURNAMENT_ORGANIZERS, values);
    },
    grabVariations: async ({ commit }) => {
      try {
        const response = await api.variations.index();

        const dataObj = response.variations

        const variationsArray = Object.keys(dataObj)
            .filter(key => !isNaN(+key))
            .map(key => dataObj[key])

        const formattedVariations = variationsArray.map(variation => ({
          value: variation.id,
          text:  variation.label
        }))

        commit(mutypes.SET_TOURNAMENT_VARIATIONS, formattedVariations);
      } catch (error) {
        console.error("Failed to fetch tournament variations:", error);
      }
    },
    removeNullElementsFromObject(context, payload) {
      Object.keys(payload.rules).forEach((el) => {
        payload.rules[el] == null && delete payload.rules[el];
      });
      Object.keys(payload.description).forEach((el) => {
        payload.description[el] == null && delete payload.description[el];
      });

      Object.keys(payload.schedule).forEach((el) => {
        payload.schedule[el] == null && delete payload.schedule[el];
      });

      return payload;
    },

    calculateRatingByTournamentId: async (
      { commit, getters },
      tournamentId
    ) => {
      return await api.tournament.calculateRating(tournamentId);
    },
    grabDivisionsList: async ({ commit, getters }, params) => {
      let response = await api.divisions.indexDivisions({
        slug: params.slug,
      });
      let valuesWithAll = [{ value: 0, text: "Все" }];
      let values = [];
      response.data.divisions.forEach((item, index) => {
        values[index] = {
          value: item.id,
          text: item.name.ru,
        };
      });
      valuesWithAll.push(...values);
      commit(mutypes.SET_TOURNAMENT_DIVISIONS, valuesWithAll);
    },
    generateTournament: async ({ commit, getters }) => {
      let response = await api.tournament.generateTournament();
      return response;
    },
  },
};
