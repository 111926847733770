<template>
  <div>
    <Breadcrumbs main="Турниры" title="Cоздать групповой этап"></Breadcrumbs>
    <div class="container">

      <b-card>
        <h1>Создание группы для {{currentStage?.name}}</h1>
        <b-form >
          <div>
            <label class="mb-2"><b>Название группы</b></label>
            <ValidationProvider rules="min:2|required" v-slot="{errors}">
              <b-form-input v-model="newGroupName" class="mb-4"></b-form-input>
              <div class="text-danger">{{errors[0]}}</div>
            </ValidationProvider>
            <label><b>Cлот</b></label>
            <ValidationProvider rules="required" v-slot="{errors}">
              <b-form-input type="number" v-model="newGroupSlot"></b-form-input>
              <div class="text-danger">{{errors[0]}}</div>
            </ValidationProvider>
            <label class="mt-3"><b>Дата</b></label>
            <ValidationProvider rules="required" v-slot="{errors}">
              <DatePicker mode="dateTime" v-model="newGroupData" is24hr :model-config="modelConfig" :popover="{visibility: 'click'}">
                <template v-slot="{inputValue, inputEvents}">
                  <b-form-input :value="inputValue" v-on="inputEvents"/>
                </template>
              </DatePicker>
              <div class="text-danger">{{errors[0]}}</div>
            </ValidationProvider>
            <label class="mt-3"><b>Статус</b></label>
            <ValidationProvider rules="required" v-slot="{errors}">
              <b-form-select v-model="newGroupStatus" :options="newGroupStatusOptions"></b-form-select>
              <div class="text-danger">{{errors[0]}}</div>
            </ValidationProvider>

          </div>
          <div class="mt-5 d-flex justify-content-center flex-wrap">
            <button class="mt-2 btn btn-success mr-3 " @click.prevent="storeTournamentStageGroupPost" :disabled="newGroupName == ''">Cохранить</button>
            <button class="mt-2 btn btn-danger mr-3" @click.prevent="$router.go(-1)">Назад</button>
          </div>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import {TournamentStandingsAdapterMixin} from "@/mixins/Tournament/TournamentStandingsAdapterMixin";
import {TournamentStageGroupAdapterMixin} from "@/mixins/Tournament/TournamentStageGroupAdapterMixin";
import {TournamentStageAdapterMixin} from "@/mixins/Tournament/TournamentStageAdapterMixin";
import { DatePicker } from 'v-calendar';


export default {
  mixins: [TournamentStandingsAdapterMixin, TournamentStageGroupAdapterMixin, TournamentStageAdapterMixin],
  name: 'CreateGroupStageStandingsTournament',
  components: {DatePicker},
  data() {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
    }
  },
  mounted() {
    this.showTournamentStage(this.$route.params.stage_id)
  },
}
</script>
