import { render, staticRenderFns } from "./SeoList.vue?vue&type=template&id=7ea87970&scoped=true"
import script from "./SeoList.vue?vue&type=script&lang=js"
export * from "./SeoList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ea87970",
  null
  
)

export default component.exports