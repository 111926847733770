<template>
  <div class="stages-for-sidebar">
    <SidebarBlock class="mb-4" header-between>
      <template #header>
        <span class="block-name">Этапы</span>
        <button v-if="!isCompletedTournament" class="action-button" @click="isShowAddStageModal = true">Создать этап</button>
      </template>
      <template #body>
        <Tabs :list="stages"
              :only-view-action="isCompletedTournament"
              v-model="selectedStageId"
              @view="viewStage"
              @change="changeStage"
              @edit="editStage"
              @delete="deleteStage"
              tab-name="этап"
        >
          <template #item="{ item: { item: { stage_rounds, status_item, id, name }, isActive } }">
            <div class="tab-label">
              <span>{{ name }}</span>
              <div>
                <span v-b-tooltip.hover.top="`Количество раундов`">{{ stage_rounds.length }}</span>
                <component is="img"
                           :src="getStatusStageIconData(status_item?.code, isActive).source"
                           v-b-tooltip.hover.top="getStatusStageIconData(status_item?.code, isActive).tooltip" />
              </div>
            </div>
          </template>
          <template #moreActions="{ item: { item: { stage_rounds, id, status_item, format_id }, itemIdx } }">
            <button @click="generateGridForStage(id, stage_rounds)"
                    v-if="status_item?.code === 'deactivated' && stageTypesById.get(format_id).name === 'single' ||
                    stageTypesById.get(format_id).name === 'bounty' "
                    v-b-tooltip.hover.top="getActionToGenerate(stage_rounds).text"
                    :class="[stage_rounds.length && 'regenerate-button']">
              <img :src="recycleBlackIcon" alt="">
            </button>
          </template>
          <template #pug><h6>Этапов нет</h6></template>
        </Tabs>
      </template>
    </SidebarBlock>
    <Modal name="AddStageTournament" title="Создание этапа" v-model="isShowAddStageModal" @submit="submitCreateStage" />
    <Modal name="EditStageTournament" title="Редактирование этапа" v-model="isShowEditStageModal" :params="{ id: selectedStageId }"/>
    <Modal name="DeleteStageTournament" title="Удаление этапа" v-model="isShowDeleteStageModal" :params="{ stage: selectedStage }"/>
    <Modal name="GenerateGridForStageTournament" title="Генерация таблицы" v-model="isShowGenerateGridForStageTournamentModal" :params="{ stage: selectedStage }" no-close-on-backdrop />
    <Modal name="ReviewStageTournament" title="Просмотр этапа" v-model="isShowReviewStageModal" :params="{ stage: selectedStage }" />
    <Modal name="RegenerateGridForStageTournament" title="Повторная генерация таблицы" v-model="isShowRegenerateGridForStageTournamentModal" :params="{ stage: selectedStage }" no-close-on-backdrop />
  </div>
</template>

<script>
// Modules
import {mapGetters, mapMutations} from 'vuex'

// Components
import SidebarBlock from "@/components/Tournament/SidebarBlock.vue";
import Tabs from "@/components/Tournament/Tabs.vue";
import Modal from '@/components/modals/index.vue'

// Icons
import recycleBlackIcon from '@/assets/icons/recycle.svg'

// Dictionary
import { stageStatusesMap, stageTypesById } from "@/common/tournamentCommon";

export default {
  name: "StagesForSidebar",
  components: {
    SidebarBlock,
    Tabs,
    Modal
  },
  computed: {
    ...mapGetters('moduleNewTournament', ['stages', 'isCompletedTournament', 'selectedStage', 'rounds', 'formatBySelectedStage']),
    selectedStageId: {
      get() { return this.$store.state.moduleNewTournament.selectedStageId },
      set(newValue) { this.setFirstLevelData({ name: 'selectedStageId', value: newValue }) }
    },
    selectedRoundId: {
      get() { return this.$store.state.moduleNewTournament.selectedRoundId },
      set(newValue) { this.setFirstLevelData({ name: 'selectedRoundId', value: newValue }) }
    },
    selectedGroupId: {
      get() { return this.$store.state.moduleNewTournament.selectedGroupId },
      set(newValue) { this.setFirstLevelData({ name: 'selectedGroupId', value: newValue }) }
    },
  },
  data() {
    return {
      stageTypesById,
      stageStatusesMap,
      recycleBlackIcon,
      isShowAddStageModal: false,
      isShowEditStageModal: false,
      isShowDeleteStageModal: false,
      isShowGenerateGridForStageTournamentModal: false,
      isShowRegenerateGridForStageTournamentModal: false,
      isShowReviewStageModal: false
    }
  },
  methods: {
    ...mapMutations('moduleNewTournament', ['setFirstLevelData']),
    submitCreateStage() {
      this.changeStage({ id: this.stages.at(-1).id || this.stages[0].id })
    },
    setNewStage(id) {
      this.selectedStageId = id
      this.selectedRoundId = null
      this.selectedGroupId = null
    },
    viewStage(id) {
      this.changeStage({ id })
      this.isShowReviewStageModal = true
    },
    changeStage(stage) {
      this.setNewStage(stage.id)
      this.$emit('changeWorkspace', null)
      this.$nextTick(async () => {
        if (this.formatBySelectedStage === 'single' && this.rounds.length) {
          await this.$emit('changeWorkspace', 'LoadTeams')
          this.selectedRoundId = this.rounds[0].id
          await this.$store.dispatch('moduleNewTournament/getGroupsWithTeamsByMLBB', { roundId: this.selectedRoundId })
        }
        this.$emit('changeWorkspace', undefined)
      })
    },
    editStage(id) {
      this.changeStage({ id })
      this.isShowEditStageModal = true
    },
    deleteStage(id) {
      this.changeStage({ id })
      this.isShowDeleteStageModal = true
    },
    generateGridForStage(id, stage_rounds) {
      this.changeStage({ id })
      if(!stage_rounds.length) {return this.isShowGenerateGridForStageTournamentModal = true}
      return this.isShowRegenerateGridForStageTournamentModal = true
    },
    getStatusStageIconData(codeName, isActive) {
      const statusData = stageStatusesMap.get(codeName)

      if (statusData) return {
        source: statusData[isActive ? 'whiteIcon' : 'blackIcon'],
        tooltip: statusData.text
      }
      return false
    },
    getActionToGenerate(stage_rounds) {
      if (!stage_rounds.length) return { text: 'Сгенерировать таблицу'}
      if (stage_rounds.length) return { text: 'Сгенерировать заново'}
      return false
    }
  },
  mounted() {
    if (this.stages && this.stages.length) this.changeStage(this.stages[0])
  }
}
</script>

<style scoped>
.regenerate-button {
  background-color: #eee827;
}
</style>