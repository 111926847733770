<template>
    <div>
      <div>
        <b-modal centered id="modal-StageDelete" title="Предупреждение" v-model="show">
          <p class="my-4">Вы действительно хотите удалить этап?</p>
          <template #modal-footer>
            <div>
              <b-btn variant="primary" class="mr-3" @click="deleteButton(selectedStageId)">Подтвердить</b-btn>
              <b-btn variant="danger" @click="show=false">Отмена</b-btn>
            </div>
          </template>
        </b-modal>
      </div>

      <div>
        <b-modal centered id="modal-GroupDelete" title="Предупреждение" v-model="showGroup">
          <p class="my-4">Вы действительно хотите удалить текущую группу?</p>
          <template #modal-footer>
            <div>
              <b-btn variant="primary" class="mr-3" @click="deleteStageGroup(selectedGroupId)">Подтвердить</b-btn>
              <b-btn variant="danger" @click="showGroup=false">Отмена</b-btn>
            </div>
          </template>
        </b-modal>
      </div>

      <div>
        <b-modal centered id="modal-endTournament" title="Предупреждение" v-model="showFinishTournament">
          <p class="my-4">Вы действительно хотите завершить турнир?</p>
          <label><b>Дата</b></label>
          <DatePicker mode="dateTime" v-model='testValue'  is24hr :model-config="modelConfig" :popover="{visibility: 'click'}">
            <template v-slot="{inputValue, inputEvents}">
              <b-form-input :value="inputValue" v-on="inputEvents"/>
            </template>
          </DatePicker>
          <template #modal-footer>
            <div>
              <b-btn variant="primary" class="mr-3" @click="finishTournamentBtn($route.params.standingId,testValue)">Подтвердить</b-btn>
              <b-btn variant="danger" @click="showFinishTournament=false">Отмена</b-btn>
            </div>
          </template>
        </b-modal>
      </div>


      <Breadcrumbs main="Турниры" :title="tournamentName"></Breadcrumbs>
      <div class="container" v-if="!loadStandingTournament">
        <div class="text-center pt-5">
          <h1 class="mb-3">{{ tournamentName }}</h1>
          <b-btn :variant="tournamentStandingCurrentStatus.id === 4 ? 'danger' : 'primary'" class="mb-4" @click="showFinishTournament=true" :disabled="tournamentStandingCurrentStatus.id === 4">{{tournamentStandingCurrentStatus.code}}</b-btn>
        </div>
        <div class="card pt-5">
          <div class="d-flex justify-content-center flex-wrap" >
            <button class="mt-2 mr-2 btn btn-primary"  @click="$router.push({name: 'create_tournament_stage', params: { id: $route.params.standingId }})">Создать этап</button>
            <button class="mt-2 mr-2 btn btn-info"  @click="$router.push({name: 'edit_tournament_stage', params: { standingId: $route.params.standingId, stage_id: selectedStageId}})">Редактировать текущий этап</button>
            <button class="mt-2 btn btn-danger" :disabled="tournamentStandingCurrentStatus.id === 4"  @click="show = true">Удалить текущий этап</button>
          </div>
          <!--        <div class="mt-2 d-flex justify-content-center">-->
          <!--          <button class="btn btn-dark mr-2">Посчитать рейтинг за турнир</button>-->
          <!--          <button class="btn btn-primary mr-2">Запросить подтверждение</button>-->
          <!--        </div>-->
          <div class="pt-5 d-flex justify-content-center">
            <b-tabs nav-class="justify-content-center">
              <b-tab @click="selectStage(tournamentStage.id)" v-for="tournamentStage in tournamentStages"
                     :key="tournamentStage.id"  :active="tournamentStage.id === selectedStageId"  ref="get">
                <template #title>
                  <div class="d-flex justify-content-center align-items-center">
                    <div>{{tournamentStage.name}}</div>
                  <img :src="tournamentStage.status ? require(`@/assets/images/${tournamentStage.status?.code}.svg`) : ''" width="12" height="12" class="ml-1"/>
                  </div>
                </template>
                <div class="d-flex justify-content-center flex-wrap mt-3 mb-2">
                  <button class="mt-2 btn btn-success mr-2"
                          @click="$router.push({name: 'create_group_tournament', params:{stage_id:selectedStageId}})">
                    Создать группу
                  </button>
                  <button class="mt-2 btn btn-warning mr-2"
                          @click="$router.push({name: 'edit_group_tournament' , params:{group_id:selectedGroupId}})">
                    Редактировать текущую группу
                  </button>
                  <button class="mt-2 btn btn-danger"  @click="showGroup = true" :disabled="tournamentStandingCurrentStatus.id === 4">Удалить текущую
                    группу
                  </button>
                </div>
                <div class="d-flex justify-content-center flex-wrap">
                  <div>
                      <router-link :to="{name:'add_team',
                      params: {standingId: $route.params.standingId,
                              stage_id:selectedStageId,
                              group_id:selectedGroupId}}">
                        <button class="mt-2 btn btn-primary mr-1">Добавить команду</button>
                    </router-link>
                  </div>

                  <div>
                    <b-btn v-b-toggle="'add_team_in_next_stage'" class="mt-2 btn btn-info">
                      <i class="fa fa-users"></i>
                      Перенести в след. этап
                    </b-btn>
                    <b-collapse :id="'add_team_in_next_stage'">
                      <div class="p-0 mt-3">
                        <div class="align-items-center">
                          <div>
                            Выбрать стадию
                          </div>
                        <b-select
                            @change="filterGroupsForNextStageTransfer"
                            v-model="selectedNextStage"
                        >
                          <option
                              v-for="stage in tournamentStages"
                              :key="'stage_select_next_' + stage.id"
                              :value="stage.id"
                          >
                            {{ stage.name }}
                          </option>
                        </b-select>
                        </div>
                        <div
                            v-if="groupsForNextStage.length > 0"
                            class="mt-2 align-items-center"
                        >
                          <div>
                          Выбрать группу
                          </div>
                          <b-select
                              v-model="selectedNextGroup"
                          >
                            <option
                                value="null"
                            >
                              не выбрано
                            </option>
                            <option
                                v-for="group in groupsForNextStage"
                                :key="'group_select_next_' + group.id"
                                :value="group.id"
                            >
                              {{ group.name }}
                            </option>
                          </b-select>
                        </div>
                        <div>
                          <button
                              class="mt-2 btn btn-primary w-100"
                              @click="transferTeamToNextStageWithMessage"
                          >
                            Перенести выбранные команды
                          </button>
                        </div>

                      </div>
                    </b-collapse>
                  </div>

                  <div>
                  <button class="mt-2 btn btn-warning ml-1 mr-1"
                          @click="$router.push({name: 'change_tournament_group_matches' , params:{group_id:selectedGroupId}})"
                  >Создание матчей
                  </button>
                  </div>

<!--                  <div>-->
<!--                  <button class="mt-2 btn btn-dark">Все группы</button>-->
<!--                  </div>-->
                    <div>
                        <button  @click="$router.push({name: 'live', params:{group_id:selectedGroupId, standingId: $route.params.standingId,
                        stage_id:selectedStageId}})"
                                 class="ml-1 mt-2 btn btn-primary">Live</button>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-5  mb-5">
                  <b-tabs nav-class="justify-content-center text-center flex-wrap">
                    <b-tab @click="selectGroup(stageGroup)" v-for="stageGroup in tournamentGroups"
                           :title="stageGroup.name" :active="stageGroup.id === selectedGroupId"  nav-class="mr-2 mb-2" :key="stageGroup.id">
                      <template #title>
                        <div class="d-flex align-items-center">
                          <div>{{stageGroup.name}}  {{stageGroup.game_time}} {{stageGroup?.group_results_count}} / {{stageGroup.slot_count}}</div>
                          <img :src="stageGroup.status ? require(`@/assets/images/${stageGroup.status?.code}.svg`) : ''" width="12" height="12" class="ml-1"/>
                        </div>
                      </template>
                    </b-tab>
                  </b-tabs>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
        <InfoStandingsTournament/>
        <TeamsStandingTournament/>
      </div>

      <div class="container" v-else-if="loadStandingTournament">
        ... Идет загрузка
      </div>
    </div>
</template>

<script>
import InfoStandingsTournament from "@/pages/Tournament/StandingsTournament/InfoStandingsTournament.vue";
import TeamsStandingTournament from "@/pages/Tournament/StandingsTournament/TeamsStandingsTournament.vue";
import {TournamentStandingsAdapterMixin} from "@/mixins/Tournament/TournamentStandingsAdapterMixin";
import {TournamentStageAdapterMixin} from "@/mixins/Tournament/TournamentStageAdapterMixin";
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {TournamentStageGroupAdapterMixin} from "@/mixins/Tournament/TournamentStageGroupAdapterMixin";
import {TournamentAdapterMixin} from "@/mixins/Tournament/TournamentAdapterMixin";
import { DatePicker } from 'v-calendar';

export default {
  name: "PUGB_Tournament",
  components: {TeamsStandingTournament, InfoStandingsTournament, DatePicker},
  mixins: [
    TournamentStandingsAdapterMixin,
    TournamentStageAdapterMixin,
    ModalAdapterMixin,
    TournamentStageGroupAdapterMixin,
    TournamentAdapterMixin
  ],
  created() {
    this.grabStandingTournamentWithId(this.$route.params.standingId);
  },
  destroyed() {
    this.tournamentGroups = null
  },
  data() {
    return {
      show: false,
      showGroup: false,
      testValue: null,
      showFinishTournament: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
    }
  },
  methods: {
    async deleteButton(id) {
      this.deleteTournamentStage(id)
      this.showGroup = false
    },
    async deleteStageGroup(id) {
      this.delTournamentStageGroup(id)
      this.showGroup = false
    },
    async finishTournamentBtn(id, value) {
      this.finishTournament({id:id,value: value})
      this.showFinishTournament = false
    }
  },
}
</script>

<style scoped>

</style>