<template>
  <div>
    <b-table
        responsive
        show-empty
        stacked="md"
        :striped="isStriped"
        :bordered="isBordered"
        :fields="fieldsLogs"
        :items="userLogs"
        :current-page=0
        :per-page=10
        class="mt-5">
      <template #cell(date)="data">
        {{ data.item?.created_at ? moment(data.item?.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm') : '' }}
      </template>
      <template #cell(type)="data">
        {{ data.item?.type?.code }}
      </template>
      <template #cell(description)="data">
        <pre class="description">{{ getCodeStateMessage(data.item?.type?.code, data).log }}</pre>
      </template>
      <template #cell(who)="data">
        {{ data.item?.author?.username }}
      </template>
    </b-table>
    <b-col md="6" class="p-0">
      <b-pagination
          @change="handleLogsPageChange"
          v-model="userLogsCurrentPage"
          :total-rows="totalPagesOfUserLogs"
          :per-page="10"
          class="my-0 mt-5"
      ></b-pagination>
    </b-col>
  </div>
</template>

<script>
import api from "@/api/api";
import {getLogData} from "@/common/userLogs";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import moment from 'moment'

export default {
  name: 'UserLogs',
  mixins: [PaginationHelperMixin],
  data() {
    return {
      moment,
      fieldsLogs: [
        {key: 'id', label: '№',},
        {key: 'date', label: 'Дата',},
        {key: 'type', label: 'Тип'},
        {key: 'description', label: 'Описание',},
        {key: 'who', label: 'Кто',},
      ],
      isStriped: true,
      isBordered: true,
      userLogs: null,
      logsPageKey: 'userLogsPageNumber',
      totalPagesOfUserLogs: 1,
      userLogsCurrentPage: 1
    }
  },
  created() {
    (async () => {
      this.recordPageToStorage(this.userLogsCurrentPage, this.logsPageKey)
      const response = await api.users.userLogs({userId: this.$route.params.id, page: this.userLogsCurrentPage})
      this.userLogs = response.data?.user_logs || []
      this.totalPagesOfUserLogs = response.data?.pagination?.total
    })()
  },
  methods: {
    getCodeStateMessage(code, data) {
      return {log: `${getLogData(data)[code]}`}
    },
    async handleLogsPageChange(pageNumber) {
      const params = {page: pageNumber, userId: this.$route.params.id}
      this.recordPageToStorage(pageNumber, this.logsPageKey)
      const response = await api.users.userLogs(params)
      this.userLogs = response.data?.user_logs || []
    },
  }
}
</script>

<style scoped>
.description {
  background-color: white;
  padding: 0;
}
</style>