import adminHttp from "@/api/adminHttp";

const END_POINT = '/api/v1/admin';
const NEW_END_POINT = '/api/v1/client';

export default {
    index: (params) => adminHttp.get(NEW_END_POINT + `/mlbb-team-rating`, {params: params}),

    actualize: () => adminHttp.get(END_POINT + `/actualize-mlbb-team-rating`),
    actualizedTime: () => adminHttp.get(NEW_END_POINT + `/rating-actualize-datetime`),
}