<template>
  <div class="container-fluid">
    <Breadcrumbs main="Рейтинг" title="Мировой рейтинг" />
    <b-card>
      <header class="d-flex justify-content-between mt-3">
        <SearchRating class="w-25" />
        <Actualize :actualize-function="actualizeRating" />
      </header>
      <body class="d-flex mt-3">
      <b-table :items="teamsMlbbRatingList" :fields="teamsMlbbRatingFields">
        <template v-slot:cell(country)="{ item }">
          {{ item.country?.id }} {{item.country?.code && '(' + item.country.code + ')' }}
        </template>
      </b-table>
      </body>
      <div class="d-flex justify-content-center">
        <PaginationRating :total="paginationTotal" class="mt-3 mb-3" />
      </div>
    </b-card>
  </div>
</template>

<script>
import api from "@/api/api";

//components
import SearchRating from "@/pages/Rating/components/SearchRating.vue";
import PaginationRating from "@/pages/Rating/components/PaginationRating.vue";
import Actualize from "@/pages/Rating/components/Actualize.vue";

export default {
  components: {
    SearchRating,
    PaginationRating,
    Actualize,
  },
  data() {
    return {
      teamsMlbbRatingList: [],
      teamsMlbbRatingFields: [
        {key: 'id', label: 'ID'},
        {key: 'place', label: 'Место'},
        {key: 'name', label: 'Название'},
        {key: 'rating', label: 'Рейтинг'},
        {key: 'country', label: 'Код страны'}
      ],
      paginationTotal: 0,
      queryParams: {
        page: this.$route.query.page || '1',
      }
    };
  },
  watch: {
    "$route"(newRoute, oldRoute) {
      if (newRoute.params) {
        this.handleQueryChange();
      }
    },
  },
  methods: {
    // Обёртка для вызова функции актуализации
    async actualizeRating() {
      try {
        await api.mlbbTeamRating.actualize();
        console.log("Актуализация завершена");
      } catch (error) {
        console.error("Ошибка актуализации:", error);
      }
    },
    async handleQueryChange() {
      const queryParams = {
        page: this.$route.query.page || '1',
      };
      if (this.$route.query.search) {
        queryParams['filter[team.name]'] = this.$route.query.search;
      }
      this.fetchData(queryParams);

    },
    async fetchData(queryParams = {}) {
      try {
        this.teamsMlbbRatingList = [];
        const res = await api.mlbbTeamRating.index(queryParams);

        this.paginationTotal = Math.ceil(res.data.pagination.total / res.data.pagination.by);
        this.teamsMlbbRatingList = res.data.data
      } catch (error) {
        console.error("Error fetching world rating:", error);
      }
    },
  },
  created() {
    this.fetchData(this.queryParams);
  }
};
</script>
