<template>
  <div>
    <Breadcrumbs main="Турниры" title="Редактировать групповой этап"></Breadcrumbs>
    <div class="container">

      <b-card>
        <h1 style="font-size: 25px">Редактирование группы для {{tournamentName}}</h1>
        <h5></h5>
        <form >
          <div >
            <label class="mb-2"><b>Название группы</b></label>
            <ValidationProvider rules="min:2|required" v-slot="{errors}">
              <b-form-input v-model="tournamentStageGroup.name" class="mb-4"></b-form-input>
              <div class="text-danger">{{errors[0]}}</div>
            </ValidationProvider>
            <label><b>Cлот</b></label>
            <ValidationProvider rules="required" v-slot="{errors}">
              <b-form-input type="number" v-model="stageGroupSlot"></b-form-input>
              <div class="text-danger">{{errors[0]}}</div>
            </ValidationProvider>
            <label class="mt-2"><b>Cтатус</b></label>
            <ValidationProvider rules="required" v-slot="{errors}">
              <b-form-select v-model="stageGroupStatus.value" >
                <option
                    v-for="groupStatus in newGroupStatusOptions"
                    :key="'groupStatus-' + groupStatus.value"
                    :value="groupStatus.value">{{groupStatus.text}}
                </option>
              </b-form-select>
              <div class="text-danger">{{errors[0]}}</div>
            </ValidationProvider>
            <label class="mt-3"><b>Дата</b></label>
            <ValidationProvider rules="required" v-slot="{errors}">
              <DatePicker mode="dateTime"
                          v-model="stageGroupGameTime" :masks="masks" is24hr :model-config="modelConfig" :popover="{visibility: 'click'}">
                <template #default="{inputValue, inputEvents}">
                  <b-form-input :value="inputValue" v-on="inputEvents"/>
                </template>
              </DatePicker>
              <div class="text-danger">{{errors[0]}}</div>
            </ValidationProvider>
          </div>
          <div class="mt-5 d-flex justify-content-center flex-wrap">
            <button class="mt-2 btn btn-success mr-3" @click.prevent="updateTournamentStageGroupPut" :disabled="!tournamentStageGroup.name">Cохранить</button>
            <button class="mt-2 btn btn-danger mr-3" @click.prevent="$router.go(-1)">Назад</button>
          </div>
        </form>
      </b-card>
    </div>
  </div>
</template>


<script>
import {TournamentStandingsAdapterMixin} from "@/mixins/Tournament/TournamentStandingsAdapterMixin";
import {TournamentStageGroupAdapterMixin} from "@/mixins/Tournament/TournamentStageGroupAdapterMixin";
import {TournamentStageAdapterMixin} from "@/mixins/Tournament/TournamentStageAdapterMixin";
import { DatePicker } from 'v-calendar';


export default {
  mixins: [TournamentStandingsAdapterMixin, TournamentStageGroupAdapterMixin, TournamentStageAdapterMixin],
  name: 'EditGroupStageStandingsTournament',
  components: {DatePicker},
  data() {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
      masks: {
        input: 'YYYY-MM-DD HH:mm'
      }
    }
  },
  async created() {
    await this.showTournamentStageGroup(this.$route.params.group_id)
  },
}
</script>
