// Components
import DatePicker from "@/components/Tournament/components/DatePicker.vue";
import EditorDivisions from "@/components/Tournament/components/EditorDivisions.vue";
import EditorExtraStagePoints from "@/components/Tournament/components/EditorExtraStagePoints.vue";

// Icons
import checkBlackIcon from '@/assets/icons/check-black.svg'
import checkWhiteIcon from "@/assets/icons/check-white.svg"

import xmarkBlackIcon from '@/assets/icons/xmark-black.svg'
import xmarkWhiteIcon from '@/assets/icons/xmark-white.svg'

import StopBlackIcon from '@/assets/icons/stop-black.svg'
import RotateBlackIcon from '@/assets/icons/rotate-black.svg'
import FinishBlackIcon from '@/assets/icons/finish-black.svg'

import StopWhiteIcon from '@/assets/icons/stop-white.svg'
import RotateWhiteIcon from '@/assets/icons/rotate-white.svg'
import FinishWhiteIcon from '@/assets/icons/finish-white.svg'

export const typeRounds = [
    {
        type: 1,
        title: 'Вверхняя сетка'
    },
    {
        type: 2,
        title: 'Нижняя сетка'
    }
]
export const typeRoundsMap = typeRounds.reduce((typesState, type) => typesState.set(type.type, type), new Map())

export const stageTypes = [
    {
        id: 1,
        name: 'single',
        title: 'Single elimination'
    },
    {
        id: 2,
        name: 'double',
        title: 'Double elimination'
    },
    {
        id: 3,
        name: 'olympic',
        title: 'Групповая стадия'
    },
    {
        id: 4,
        name: 'bounty',
        title: 'Bounty'
    }
]
export const stageTypesById = stageTypes.reduce((state, type) => state.set(type.id, type), new Map())

export const stageStatuses = [
    {
        id: 1,
        code: 'active',
        blackIcon: checkBlackIcon,
        whiteIcon: checkWhiteIcon,
        text: 'Активно'
    },
    {
        id: 0,
        code: 'deactivated',
        blackIcon: xmarkBlackIcon,
        whiteIcon: xmarkWhiteIcon,
        text: 'Не активно'
    }
]
export const stageStatusesMap = stageStatuses.reduce((state, status) => state.set(status.code, status), new Map())

export const groupStatuses = [
    {
        code: 'not_started_status',
        blackIcon: StopBlackIcon,
        whiteIcon: StopWhiteIcon,
        text: 'Игра не начата',
    },
    {
        code: 'game_process_status',
        blackIcon: RotateBlackIcon,
        whiteIcon: RotateWhiteIcon,
        text: 'Игра уже идет',
    },
    {
        code: 'ended_status',
        blackIcon: FinishBlackIcon,
        whiteIcon: FinishWhiteIcon,
        text: 'Игра начата',
    }
]
export const groupStatusesMap = groupStatuses.reduce((state, status) => state.set(status.code, status), new Map())

export const fieldsByEditorStage = [
    {
        key: 'name',
        defaultValue: '',
        label: 'Название этапа',
        elementTag: 'b-form-input',
        required: true
    },
    {
        key: 'rating_coefficient',
        defaultValue: 0,
        label: 'Коэффициент этапа - Рейтинг команд',
        elementTag: 'b-form-input',
        required: true,
        type: 'number',
        step: '0.01'
    },
    {
        key: 'player_rating_coefficient',
        defaultValue: 0,
        label: 'Коэффициент этапа - Рейтинг пользователя',
        elementTag: 'b-form-input',
        required: true,
        type: 'number'
    },
    {
        key: 'replace_count',
        defaultValue: 0,
        label: 'Количество замен',
        elementTag: 'b-form-input',
        required: true,
        type: 'number'
    },
    {
        key: 'status',
        defaultValue: 0,
        label: 'Статус',
        elementTag: 'b-form-select',
        required: true,
        options: stageStatuses.map(({ id, text }) => ({ value: id, text })),
    },
    {
        key: 'threshold_rating',
        defaultValue: 0,
        label: 'Минимальной значение рейтинга для подачи в этап',
        elementTag: 'b-form-input',
        required: true,
        type: 'number',
        classList: 'full-width'
    },
    {
        key: 'level_experience_coefficient',
        defaultValue: 0,
        label: 'Коэффициент P для Level',
        elementTag: 'b-form-input',
        required: true,
        type: 'number',
        classList: 'full-width'
    },
    {
        key: 'register_slot_count',
        defaultValue: 2,
        label: 'Кол-во команд для регистрации',
        elementTag: 'b-form-input',
        required: true,
        type: 'number'
    },
    {
        key: 'register_start_date',
        defaultValue: null,
        label: 'Дата начало игры',
        elementTag: DatePicker,
        required: true
    },
    {
        key: 'register_end_date',
        defaultValue: null,
        label: 'Дата окончания регистрации',
        elementTag: DatePicker,
        required: true
    },
    {
        key: 'stage_divisions',
        defaultValue: [],
        label: 'Кол-во команд, разрешенное для подачи в этап',
        elementTag: EditorDivisions,
        required: true,
        classList: 'full-width'
    },
    {
        key: 'extra_stage_points',
        defaultValue: [],
        label: 'Экстра очки опыта для Level',
        elementTag: EditorExtraStagePoints,
        classList: 'full-width'
    },
    {
        key: 'format_id',
        defaultValue: 0,
        label: 'Формат',
        elementTag: 'b-form-select',
        required: true,
        options: stageTypes.map(({ id, title }) => ({ text: title, value: id }))
    }
]

export const fieldsByEditorStageMap = fieldsByEditorStage.reduce((state, field) => state.set(field.key, field), new Map())

export const fieldsByGamePUBGMobile = [
    fieldsByEditorStageMap.get('replace_count'),
    fieldsByEditorStageMap.get('status'),
    fieldsByEditorStageMap.get('rating_coefficient'),
    fieldsByEditorStageMap.get('player_rating_coefficient'),
    fieldsByEditorStageMap.get('threshold_rating'),
    fieldsByEditorStageMap.get('stage_divisions'),
    fieldsByEditorStageMap.get('level_experience_coefficient'),
    fieldsByEditorStageMap.get('extra_stage_points'),
]

export const fieldsByGameMLBBByStageType = [
    {
        formatId: 1,
        fields: [
            fieldsByEditorStageMap.get('rating_coefficient'),
            fieldsByEditorStageMap.get('register_slot_count'),
            fieldsByEditorStageMap.get('replace_count'),
            fieldsByEditorStageMap.get('status'),
            fieldsByEditorStageMap.get('register_start_date'),
            fieldsByEditorStageMap.get('register_end_date'),
        ]
    },
    {
        formatId: 2,
        fields: [
            fieldsByEditorStageMap.get('rating_coefficient'),
            fieldsByEditorStageMap.get('status'),
            fieldsByEditorStageMap.get('replace_count'),
            { ...fieldsByEditorStageMap.get('register_slot_count'), required: false },
            { ...fieldsByEditorStageMap.get('register_start_date'), required: false },
            { ...fieldsByEditorStageMap.get('register_end_date'), required: false },
        ]
    },
    {
        formatId: 3,
        fields: [
            fieldsByEditorStageMap.get('rating_coefficient'),
            fieldsByEditorStageMap.get('status'),
            fieldsByEditorStageMap.get('replace_count'),
        ]
    },
    {
        formatId: 4,
        fields: [
            fieldsByEditorStageMap.get('status'),
            fieldsByEditorStageMap.get('replace_count'),
        ]
    }
]

export const fieldsByGameMLBBByStageTypeMap = fieldsByGameMLBBByStageType.reduce((state, format) => state.set(format.formatId, format), new Map())