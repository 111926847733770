<template>
  <b-form-group :label="label" class="date-picker-wrapper">
    <TheDatePicker mode="dateTime"
                v-model="modelValue"
                is24hr
                :model-config="modelConfig"
                   :label="label"
                :popover="{ visibility: 'click' }">
      <template v-slot="{ inputValue, inputEvents }">
        <b-form-input :value="inputValue" v-on="inputEvents" :required="required" />
      </template>
    </TheDatePicker>
  </b-form-group>
</template>

<script>
import { DatePicker as TheDatePicker } from 'v-calendar';


export default {
  name: "DatePicker",
  components: {
    TheDatePicker
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    modelConfig: {
      type: Object,
      default: () => ({
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm:ss',
      })
    },
    required: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>