<template>
  <div class="rounds-for-sidebar">
    <SidebarBlock header-between>
      <template #header>
        <span class="block-name">Раунды</span>
        <button v-if="formatBySelectedStage === 'double' || formatBySelectedStage === 'bounty'"
                class="action-button"
                @click="isShowAddRoundModal = true">
          Создать раунд
        </button>
      </template>
    </SidebarBlock>

    <!--  Format list for single grid  -->
    <SidebarBlock v-if="formatBySelectedStage === 'single'" class="mb-4"  header-between>
      <template #body>
        <Tabs v-model="selectedRoundId"
              @change="changeRound"
              @edit="editRound"
              :list="rounds"
              tab-name="раунд"
              hideDeleteAction
              id-name="round-item-sidebar-">
          <template #item="{ item: { item, isActive } }"><TabLabelRound :data="item" /></template>
          <template #pug><h6>Раундов нет</h6></template>
        </Tabs>
      </template>
    </SidebarBlock>

    <!--  Format list for double grid  -->
    <!--  Winner rounds  -->
    <SidebarBlock v-if="formatBySelectedStage === 'double' || formatBySelectedStage === 'bounty'" class="mt-2"
                  header-between>
      <template #header>
        <span
            v-if="formatBySelectedStage === 'double'"
            class="block-name">Раунды победителей</span>
        <span
            v-if="formatBySelectedStage === 'bounty'"
            class="block-name">Верхний ряд</span>
      </template>
      <template #body>
        <Tabs v-model="selectedRoundId"
              @change="changeRound"
              @edit="editRound"
              :list="winnerRounds"
              tab-name="раунд"
              hideDeleteAction
              id-name="round-item-sidebar-">
          <template #item="{ item: { item, isActive } }"><TabLabelRound :data="item" /></template>
          <template #pug><h6>Раундов нет</h6></template>
        </Tabs>
      </template>
    </SidebarBlock>
    <!--  Loser rounds  -->
    <SidebarBlock v-if="formatBySelectedStage === 'double' || formatBySelectedStage === 'bounty'" class="mt-2 mb-4" header-between>
      <template #header>
        <span
            v-if="formatBySelectedStage === 'double'"
            class="block-name">Раунды проигравших</span>
        <span
            v-if="formatBySelectedStage === 'bounty'"
            class="block-name">Нижний ряд</span>
      </template>
      <template #body>
        <Tabs v-model="selectedRoundId"
              @change="changeRound"
              @edit="editRound"
              :list="loserRounds"
              tab-name="раунд"
              hideDeleteAction
              id-name="round-item-sidebar-">
          <template #item="{ item: { item, isActive } }"><TabLabelRound :data="item" /></template>
          <template #pug><h6>Раундов нет</h6></template>
        </Tabs>
      </template>
    </SidebarBlock>

    <!--  Modals  -->
    <Modal name="AddRoundTournament" title="Создание раунда" v-model="isShowAddRoundModal" />
    <Modal name="EditRoundTournament" title="Редактирование раунда" v-model="isShowEditRoundModal" :params="{ round: selectedRound }" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

// Components
import SidebarBlock from "@/components/Tournament/SidebarBlock.vue";
import Tabs from "@/components/Tournament/Tabs.vue";
import Modal from '@/components/modals/index.vue'
import TabLabelRound from "@/components/Tournament/MLBB/components/TabLabelRound.vue";

export default {
  name: "RoundsForSidebar",
  components: {
    SidebarBlock,
    Tabs,
    Modal,
    TabLabelRound
  },
  computed: {
    ...mapGetters('moduleNewTournament', ['rounds', 'isCompletedTournament', 'selectedStage', 'formatBySelectedStage', 'selectedRound']),
    selectedRoundId: {
      get() { return this.$store.state.moduleNewTournament.selectedRoundId },
      set(newValue) { this.setFirstLevelData({ name: 'selectedRoundId', value: newValue }) }
    },
    selectedGroupId: {
      get() { return this.$store.state.moduleNewTournament.selectedGroupId },
      set(newValue) { this.setFirstLevelData({ name: 'selectedGroupId', value: newValue }) }
    },
    winnerRounds() {
      return this.rounds.filter(({ type }) => type === 1)
    },
    loserRounds() {
      return this.rounds.filter(({ type }) => type === 2)
    }
  },
  data() {
    return {
      isShowAddRoundModal: false,
      isShowEditRoundModal: false
    }
  },
  methods: {
    ...mapMutations('moduleNewTournament', ['setFirstLevelData']),
    async changeRound() {
      await this.$emit('changeWorkspace', 'LoadTeams')
      this.selectedGroupId = null
      await this.$store.dispatch('moduleNewTournament/getGroupsWithTeamsByMLBB', { roundId: this.selectedRoundId })
      await this.$nextTick(async () => {
        await this.$emit('changeWorkspace', undefined)
      })
    },
    editRound(id) {
      this.selectedRoundId = id
      this.changeRound()
      this.isShowEditRoundModal = true
    }
  }
}
</script>