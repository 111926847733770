<template>
  <div>
    <Breadcrumbs main="Пользователь" :title='userShow.username' />
    <Modal name="UserDocuments"
           title="Верификация документов"
           v-model="isShowDocsModal"
           :size="'lg'"
           :params="{ userDocuments: userDocumentList,
                      isLoadingDocs: isLoadedUserDocs,
                      error: errorText}"/>
    <div class="container">
      <b-card>
        <form>
          <div class="row justify-content-sm-between justify-content-center mb-5">
            <img :src="userShow?.avatar?.original_url || UserImagePug"
                 class="userLogo"
                 @error="event => event.target.src = UserImagePug"
            />
            <h1>{{ userShow.id }}. {{ userShow.username }}</h1>
          </div>
        </form>
        <b-tabs>
          <b-tab title="Общее" active>
            <table class="table mt-5">
              <tr v-if="userShow?.currentBans.length">
                <th class="text-danger">Бан</th>
                <td>
                   <span v-for="(bans, idx) in userShow.currentBans" :key="bans.id">
                     Профиль {{bans?.game_name}} находится в бане от {{bans?.from}} до {{bans?.to}}
                     Причина: {{bans?.description}}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Почта пользователя</th>
                <td>{{ userShow.email }}</td>
              </tr>
              <tr>
                <th>ФИО</th>
                <td>{{ userShow.full_name == null ? 'не указано' : userShow.full_name }}</td>
              </tr>
              <tr>
                <th>Дата рождения</th>
                <td>{{ userShow.birthday == null ? 'не указано' : userShow.birthday }}</td>
              </tr>
              <tr>
                <th>Страна</th>
                <td>{{ userShow.country == null ? 'не указано' : userShow.country.name }}</td>
              </tr>
              <tr>
                <th>Документы</th>
                <td>
                  <div v-if="userDocumentList?.length" class="">
                    <div v-for="file in userDocumentList" :key="'fileId' + file.id" class="mb-2 d-flex flex-wrap">
                      <b-btn variant="light" @click="isShowDocsModal = true">{{file.name}}</b-btn>
                      <b-btn variant="danger" class="ml-2" @click="deleteDocument({docId: file.id, userId: userShow.id})">Удалить</b-btn>
                    </div>
                  </div>
                  <div v-else>Нет загруженных документов</div>
                </td>
              </tr>
              <tr>
                <th>Язык пользователя</th>
                <td>{{ userShow?.lang?.code || 'не указано' }}</td>
              </tr>
              <tr>
                <th>Статус</th>
                <td class="d-md-flex">
                  <div>{{userShow.gameProfiles?.length <= 0 ? 'Не игровой/' : 'Игровой/'}}</div>
                  <div>{{userSocialAccountsShow <= 0 ? 'Не активирован' : 'Активирован'}}</div>
                  <b-btn
                      v-if="userSocialAccountsShow <= 0"
                      @click.prevent="changeStatus({userId: userShow.id})"
                      class="mb-2 ml-4"
                      v-model="userSocialAccountsShow"
                      :id="'makeActive' + userShow.id"
                  >
                    Активировать
                  </b-btn>
                </td>
              </tr>
              <tr>
                <th>Команды Pubg mobile</th>
                <td>
                   <span v-for="team in pubgTeams" :key="'teamPubgId-' + team.id">
                       <b-btn variant="link" @click="showTeamInfo(team.id)" style="color: black">
                               {{ team.name }}
                       </b-btn>
                  </span>
                </td>
              </tr>
              <tr>
                <th>Команды MLBB</th>
                <td>
                   <span v-for="team in mlbbTeams" :key="'teamMLBBId-' + team.id">
                       <b-btn variant="link" @click="showTeamInfo(team.id)" style="color: black">
                               {{ team.name }}
                       </b-btn>
                  </span>
                </td>
              </tr>
              <tr>
                <th>Рейтинг Pubg mobile</th>
                <td>{{userShow?.ratingPubgMobile?.rating}}</td>
              </tr>
              <tr>
                <th>Уровень</th>
                <td>
                  <div></div>
                </td>
              </tr>

            </table>
          </b-tab>
          <b-tab title="Игровые профиля">
            <game_profiles
                v-for="gameProfile in userProfilesForWork"
                :key="'gameProfile-' + gameProfile.id"
                @submit="getAllData"
                :game="gameProfile"
            />
            <div class="mt-3">
              <b-btn v-if="isShowAddGameProfileMLBB" @click="addMlbbGameProfile">Добавить игровой профиль по MLBB</b-btn>
              <b-btn v-if="isShowAddGameProfilePubgMobile" @click="addPubgMobileGameProfile">Добавить игровой профиль по PUBG MOBILE</b-btn>
            </div>
          </b-tab>
          <b-tab title="Верификация, Аккаунты">
            <table class="table mt-5">
              <tr>
                <th>Соцсеть</th>
                <th>Никнейм</th>
                <th>Данные (почта/номер)</th>
                <th>Страна</th>
                <th>Дата верификации</th>
                <th>Инструменты</th>
              </tr>
              <tr v-for="account in socialAccounts" :key="account.name">
                <td class="text-capitalize">{{ account.name }}</td>
                <td>
                  <template v-if="account.name === 'telegram' && account.link">
                    <a :href="account.link">@{{ account.link.split('/').at(-1) }}</a>
                  </template>
                  <a v-else-if="account.nickname" :href="account.link">
                    {{ account.nickname }}
                  </a>
                  <template v-else>
                    -
                  </template>
                </td>
                <td>{{ account.email || account.phone || '-' }}</td>
                <td>{{ account.country || '-' }}</td>
                <td>{{ account.verifiedDate || '-' }}</td>
                <td>
                  <b-btn variant="primary"
                         v-if="!account.isPrimary"
                         @click="unlinkSocialAccount(account.id, $route.params.id)">
                    Отвязать
                  </b-btn>
                </td>
              </tr>
            </table>
          </b-tab>
          <b-tab title="Логи">
            <UserLogs/>
          </b-tab>
          <b-tab title="IP">
            <b-table
                responsive
                show-empty
                stacked="md"
                :striped="striped"
                :bordered="bordered"
                :fields="fieldsIp"
                :items="userLoginLogs"
                :current-page=0
                :per-page=10
                class="mt-5">
              <template #cell(country)="data">
                {{data.item?.country.name}}
              </template>
              <template #cell(ip)="data">
                {{data.item?.ip}}
              </template>
              <template #cell(device)="data">
                {{data.item?.data?.device}}
              </template>
              <template #cell(browser)="data">
                {{data.item?.data?.browser}}
              </template>
              <template #cell(platform)="data">
                {{data.item?.data?.platform}}
              </template>
              <template #cell(created_at)="data">
                {{data.item.created_at ? moment(data.item.created_at, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm') : ''}}
              </template>
            </b-table>
            <b-col md="6" class="p-0">
              <b-pagination
                  @change="handlePageChange"
                  v-model="userLoginLogsCurrentPage"
                  :total-rows="userLoginLogsTotalPagination"
                  :per-page="10"
                  class="my-0 mt-5"
              ></b-pagination>
            </b-col>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import {UsersAdapterMixin} from "@/mixins/Users/UsersAdapterMixin";
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {TeamsAdapterMixin} from "@/mixins/Teams/TeamsAdapterMixin";
import game_profiles from "@/components/game_profiles";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import moment from 'moment'
import Modal from '@/components/modals/index.vue'
import api from "@/api/api";
import Vue from "vue";
import UserImagePug from '@/assets/images/pugs/team.png'
import UserLogs from "@/components/User/UserLogs";

export default {
  name: 'UserInfo',
  mixins: [UsersAdapterMixin, ModalAdapterMixin, TeamsAdapterMixin, PaginationHelperMixin],
  components: {
    game_profiles,
    Modal,
    UserLogs
  },
  computed: {
    userProfilesForWork() {
      return [...this.userShow?.gameProfiles, ...this.newGameProfiles]
    },
    isShowAddGameProfilePubgMobile() {
      return !this.userProfilesForWork.find(({ game }) => game.slug === 'pubg_mobile')
    },
    isShowAddGameProfileMLBB() {
      return !this.userProfilesForWork.find(({ game }) => game.slug === 'mlbb')
    },
  },
  created() {
    this.getAllData();
    (async () => {
      this.isLoadedUserDocs = true
      const response = await api.docs.showUserDocs(this.$route.params.id)
          .catch((error) => {
            this.errorText = 'For view documents ' + error.response.data.message
          })
      this.userDocumentList = response.data.user_documents
      this.isLoadedUserDocs = false
    })()
    if(!this.isShowDocsModal) this.userDocumentList = []
  },
  data() {
    return {
      // emptyGameProfiles: {
      //       pubg: {id: 0, fields: {nick: "", pubg_id: ""}, game_id: 1, status_item: {id: 0, code: "", name: ""}, user_id: 0, game: {id:1, name: 'pubg mobile', slug:'pubg_mobile', tournament_result_fields: {kill_pts: {personal: true, required: "required", type: "number", validation: ["required", "int"]}, place: {personal: false, required: "required", type: "number", validation: ["required", "int"]}}}},
      //       mlbb: {id: 0, fields: {nick: "", mlbb_id: ""}, game_id: 3, status_item: {id: 0, code: "", name: ""}, user_id: 0, game: {id:1, name: 'mlbb', slug:'mlbb', tournament_result_fields: {kill_pts: {personal: true, required: "required", type: "number", validation: ["required", "int"]}, place: {personal: false, required: "required", type: "number", validation: ["required", "int"]}}}},
      // },
      UserImagePug,
      moment,
      pageKey: 'pageNumberUserLoginLogs',
      isShowDocsModal: false,
      nick: '',
      pubg_id: '',
      fieldsIp: [
        {key: 'id', label: '№',},
        {key: 'country', label: 'Страна',},
        {key: 'ip', label: 'Ip'},
        {key: 'device', label: 'Устройство'},
        {key: 'browser', label: 'Браузер'},
        {key: 'platform', label: 'Платформа'},
        {key: 'created_at', label: 'Дата авторизации'},
      ],
      striped: true,
      bordered: true,
      newGameProfiles: [],
      mlbbTeams: [],
      pubgTeams: [],
      isLoadingDelete: false,
      userDocumentList: [],
      isLoadedUserDocs: false,
      errorText: null
    }
  },
  methods: {
    addPubgMobileGameProfile() {
      this.newGameProfiles.push({
        user_id: this.userShow.id,
        game_id: 1,
        game: {
          slug: 'pubg_mobile',
          name: 'PUBG MOBILE'
        },
        fields: {
          pubg_id: '',
          nick: ''
        }
      })
    },
    addMlbbGameProfile() {
      this.newGameProfiles.push({
        user_id: this.userShow.id,
        game_id: 3,
        game: {
          slug: 'mlbb',
          name: 'MLBB'
        },
        fields: {
          mlbb_id: '',
          nick: ''
        }
      })
    },
    async getAllData() {
      Promise.all([
        await this.showUser(this.$route.params.id),
        this.recordPageToStorage(this.userLoginLogsCurrentPage, this.pageKey),
        this.grabUserLoginLogs({
          pageNumber: this.getCurrentPageNumber(this.pageKey),
          userId: this.$route.params.id,
        })
      ])
      this.newGameProfiles = []
      this.mlbbTeams = this.userShow.teams.filter((team) => team.game_id === 3)
      this.pubgTeams = this.userShow.teams.filter((team) => team.game_id === 1)
    },
        // getUserGameProfiles() {
        //     let gameId = this.userShow?.gameProfiles.map((field) => field.game_id)
        //
        //     gameId.includes(1) && !gameId.includes(3) && this.userShow?.gameProfiles.push(this.emptyGameProfiles.mlbb)
        //     gameId.includes(3) && !gameId.includes(1) && this.userShow?.gameProfiles.push(this.emptyGameProfiles.pubg)
        //     !this.userShow?.gameProfiles.length && this.userShow?.gameProfiles.push(this.emptyGameProfiles.mlbb, this.emptyGameProfiles.pubg)
        // }
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.grabUserLoginLogs({
        pageNumber: pageNumber,
        userId: this.$route.params.id,
      })
    },
    async deleteDocument(params) {
      await api.docs.delete(params.docId)
          .then(() => {
            Vue.toasted.success('Документ успешно удален', {duration: 3000})
            this.userDocumentList = this.userDocumentList.filter((doc) => doc.id !== params.docId)
          })
          .catch((error) => {
            Vue.toasted.error(error.response.data.message, {duration: 3000})
          })
    }
  }
}
</script>

<style scoped>
.userLogo {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%

}
</style>
