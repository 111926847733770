<template>
  <Dashboard id="tournament-wrapper">
    <template #workspace>
      <transition :name="selectedWorkspace ? 'slide-left' : 'slide-right'" mode="out-in">
        <component v-if="workspaceComponent"
                   @changeWorkspace="changeWorkspace"
                   :is="workspaceComponent" />
      </transition>
    </template>
    <template #sidebar>
      <Sidebar @changeWorkspace="changeWorkspace" />
    </template>
  </Dashboard>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import Dashboard from "@/components/Tournament/Dashboard.vue";
import Sidebar from "@/components/Tournament/MLBB/Sidebar.vue";
import MainActions from "@/components/Tournament/MainActions.vue";

import { workspaces } from '@/components/Tournament/MLBB/workspaces/list';

export default {
  name: "MLBB_Tournament",
  components: {
    Dashboard,
    Sidebar,
    MainActions
  },
  data() {
    return {
      selectedWorkspace: 'AddTeam',
    }
  },
  computed: {
    ...mapGetters('moduleNewTournament', ['selectedGroup', 'selectedStage', 'formatBySelectedStage']),
    ...mapState('moduleNewTournament', ['selectedStageId', 'selectedRoundId', 'selectedGroupId', 'groupsWithTeamsByMLBB', 'teamsByGroup']),
    forWatcherWorkspaces() { return [this.selectedStageId] },
    workspaceComponent() {
      if (this.selectedWorkspace === null) return false

      if (this.selectedWorkspace) return workspaces.get(this.selectedWorkspace)

      // For single stage
      if (this.formatBySelectedStage === 'single') {

        // When generated groups
        if (this.selectedGroupId || this.selectedRoundId) return workspaces.get('HaveTeams')

        // When stage empty
        if (this.selectedStageId) return workspaces.get('AddTeam')

      }

      // For double stage
      if (this.formatBySelectedStage === 'double' || this.formatBySelectedStage === 'bounty') {

        // When generated groups
        if (this.selectedGroupId || this.selectedRoundId) return workspaces.get('HaveTeams')

        // When stage empty
        if (this.selectedStageId) return workspaces.get('AddTeam')

      }

      // For olympic stage and bounty stage
      if (this.formatBySelectedStage === 'olympic' || this.formatBySelectedStage === 'bounty') {
        if (this.selectedStageId && this.selectedGroupId && (!this.teamsByGroup || !this.teamsByGroup.length)) return workspaces.get('NotTeams')
        if (this.selectedStageId && this.selectedGroupId) return workspaces.get('HaveTeamsOlympic')
      }

      return false
    },
  },
  methods: {
    changeWorkspace(workspaceName) {
      this.selectedWorkspace = workspaceName
    }
  },
  watch: {
    forWatcherWorkspaces: {
      deep: true,
      immediate: true,
      handler() {
        this.changeWorkspace(null)
      }
    }
  }
}
</script>

<style lang="scss">
.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 170px) !important;
}
</style>

<style scoped lang="scss">
.slide-left-enter-active, .slide-left-leave-active {
  transition: all .3s;
}
.slide-left-enter, .slide-left-leave-active {
  opacity: 0;
}
.slide-left-enter {
  transform: translateX(50px);
}
.slide-left-leave-active {
  transform: translateX(-50px);
}

.slide-right-enter-active, .slide-right-leave-active {
  transition: all .3s;
}
.slide-right-enter, .slide-right-leave-active {
  opacity: 0;
}
.slide-right-enter {
  transform: translateX(-50px);
}
.slide-right-leave-active {
  transform: translateX(50px);
}

.tournament-wrapper {
  padding-top: 25px;
  display: grid;
  grid-template-columns: auto minmax(200px, 350px);
  gap: 20px;
  padding-left: 20px;
  //height: 100%;

  overflow-y: auto;
  max-height: 80vh;

  //.workspace,
  .sidebar {
    background: white;
    //z-index: 9999;
    padding: 20px;
    border-radius: 10px;
  }

  .sidebar {
    position: sticky;
    top: 0;
    right: 0;
    max-height: 80vh;
    overflow-y: auto;

    & > *:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .label-item {
    .header-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .custom-dropdown::v-deep {
      .dropdown-toggle {
        font-size: 11px;
        border-radius: 10px;
        padding: 2px 9px 2px 9px;

        &::after {
          margin-left: 7px;
        }
      }
    }

    h2 {
      font-size: 18px;
      margin-bottom: 25px;
    }

    span {
      font-size: 12px;
      color: #8080809c;
      display: block;

      &.right {
        text-align: right;
      }
    }

    p {
      margin: 0;
      font-size: 15px;
    }

    .customized-select {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      font-size: 14px;
    }

    .action-button {
      margin-top: 10px;
      width: 100%;
      //border-color: #a927f9;
      //color: #a927f9;
      //border-color: #a927f999;
      //color: #a927f999;
      border-radius: 10px;

      &:hover, &:active, &:focus {
        //background-color: #a927f9 !important;
        //border-color: #a927f9 !important;
        //background-color: #a927f999 !important;
        //border-color: #a927f999 !important;
        color: white;
      }
    }
  }

  .custom-tabs {
    margin-top: 10px;

    & > .custom-tab:not(:last-child) {
      position: relative;

      &::before {
        content: '';
        width: 90%;
        height: 1px;
        background: #80808033;

        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .custom-tab {
      display: flex;
      align-items: center;
      position: relative;

      padding: 10px 15px;
      cursor: pointer;
      border-radius: 10px;
      transition: .2s;
                                                                                                                                                                                                                                                                                                                                                                     
      &:hover {
        //background: #a927f9a1;
        background: #dad6ff;
        //color: white;
        color: #7366ff;
      }

      img {
        width: 15px;
        height: 15px;
        margin-left: 10px;
      }

      &.active {
        //background: #a927f9c2;
        background: #7366ff;
        color: white;
        //box-shadow: 0 0 7px 0px #be5bfa;
        box-shadow: 0 0 7px 0 #7366ff;
        //border-bottom-left-radius: 0;
        //border-top-left-radius: 0;

        //&::before {
        //  content: '';
        //  width: 50px;
        //  height: 100%;
        //  background: #7366ff;
        //  left: 0;
        //  transform: translateX(-100%);
        //  box-shadow: 5px 0 7px 0 #7366ff;
        //}
      }
    }
  }

  .groups {
    display: grid;
    grid-template-columns: 100%;
    gap: 20px;

    .actions-header {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 9;
      box-shadow: 0 0 14px 0px #00000045;

      h6 {
        margin: 0;
      }

      .group-header {
        margin: 0 !important;
      }
    }

    .group {
      background: white;
      //z-index: 9;
      padding: 20px;
      border-radius: 10px;
      transition: .2s;

      &.active {
        background: #7366ffbf;
      }

      .group-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .header-actions {
          display: flex;

          .header-action {
            border-radius: 15px;
            font-size: 11px;
            padding: 5px 18px;
          }
          .header-action-2 {
            border-radius: 15px;
            font-size: 11px;
            padding: 5px 18px;
            color: #6c757d;
            border-color: #6c757d;
            margin-right: 10px;

            &:hover, &:active, &:focus {
              color: #fff !important;
              background-color: #6c757d !important;
              border-color: #6c757d !important;
            }
          }
        }
      }

      //margin-bottom: 20px;

      h5 {
        margin: 0;
        font-size: 16px;
      }

      .group-teams {
        display: grid;
        grid-template-columns: 42px 200px 150px auto;
        gap: 10px;

        & > * {
          align-self: center;
        }

        & > *:first-child {
          justify-self: center;
          width: 100%;
        }

        .action-checkbox {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          & > * {
            height: 42px;

            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;

            &::v-deep {
              label::before,
              label::after {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }
        }

        .wins-counters {
          padding: 2px 0;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .team-list {

          & > *:not(:last-child) {
            border-bottom: 1px solid gray;
          }

          & > *:last-child {
            //border-radius: 0 0 10px 10px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }

          & > *:first-child {
            //border-radius: 10px 10px 0 0;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }

          .team {
            padding: 10px 18px;
            //background: #8080801f;
            //background: #f5f7fa80;
            background: #f5f7fa;
            border-color: #dae0ff;
            cursor: move;
          }
        }

        .deletedc {
          justify-self: end;
          height: 100%;
          padding: 2px 0;

          display: flex;
          flex-direction: column;
          justify-content: space-between;

          button {
            width: 37px;
            height: 37px;
            padding: 0;
            background: #DC3545C8 !important;
            //border-radius: 10px;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
</style>