import adminHttp from "/src/api/adminHttp";
import adminHttpImg from "@/api/adminHttpImg";

const END_POINT = '/api/admin/tournament';
const NEW_END_POINT = '/api/v1/admin/tournament';
const ENTRY_END_POINT = '/api/admin/entry';

export default {
    generate: ({id, stageId}) => adminHttp.post(END_POINT + `/grid-generate/${id}/${stageId}`),
    newGenerate: (stageId) => adminHttp.post(`/api/v1/admin/tournament/grid-generate/${stageId}`),
    
    /**
     * GET: Tournament, can be filtered by params
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: (params) => adminHttp.get(NEW_END_POINT + '/inner', {params: params}),

    indexOuter: (params) => adminHttp.get(END_POINT + '/outer', {params: params}),

    /**
     * DELETE: Tournament, can be deleted by id
     * @returns {Promise<AxiosResponse<any>>}
     */
    destroy: (id) => adminHttp.delete(NEW_END_POINT + `/${id}`),

    /**
     * POST: Tournament, can be stored by id
     * @returns {Promise<AxiosResponse<any>>}
     */
    store: (params) => adminHttpImg.post(NEW_END_POINT, params),

    /**
     * PUT: Tournament, can be updated by id
     * @returns {Promise<AxiosResponse<any>>}
     */
    update: (id, data) => adminHttpImg.post(NEW_END_POINT + `/${id}`, data),

    /**
     * Get: Tournament, can be edit by id
     * @returns {Promise<AxiosResponse<any>>}
     */
    grabTournament: (id) => adminHttp.get(NEW_END_POINT + `/${id}`),

    tournamentTeams: (params) => adminHttp.get(NEW_END_POINT + `/teams/${params.id}`, {params: params}),

    /**
     * Get: Tournament, can be edit by id
     * @returns {Promise<AxiosResponse<any>>}
     */
    grabTournamentStandings: (id) => adminHttp.get(NEW_END_POINT + `/res/${id}`),

    /**
     * Get: Tournament entries
     * @returns {Promise<AxiosResponse<any>>}
     */
    grabTournamentEntries: (id, params) => adminHttp.get(`api/v1/admin/entry/tournament/${id}`, {params: params}),

    /**
     * DELETE: Tournament entries, can be deleted by id
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteTournamentEntry: (id) => adminHttp.delete(`api/v1/admin/entry/${id}`),

    /**
     * DELETE: Tournament entries, can be deleted by id
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteTournamentEntriesTeam: (id) => adminHttp.delete(END_POINT + `/${id}`),

    /**
     * Get: Tournament entry by id
     * @returns {Promise<AxiosResponse<any>>}
     */
    grabTournamentEntry: (params) => adminHttp.get(`api/v1/admin/entry/tournament/${params.tournamentId}/${params.entryId}`),

    /**
     * Update: Tournament entry status (accept or reject)
     * @returns {Promise<AxiosResponse<any>>}
     */
    changeStatusTournamentEntry: (params) => adminHttp.put(ENTRY_END_POINT + `/change_status/${params.entryId}`, params),
    
    /**
     * Update: Tournament entry status (accept or reject)
     * @returns {Promise<AxiosResponse<any>>}
     */
    addTournamentPrizes: (tournamentId, data) => adminHttp.post(NEW_END_POINT + `-prize-fund/tournament/${tournamentId}`, data),

    /**
     * Post: Tournament entry accept
     * @returns {Promise<AxiosResponse<any>>}
     */
    acceptTournamentEntry: (id) => adminHttp.post(`api/v1/admin/entry/accept/${id}`),

    rejectTournamentEntry: (params) => adminHttp.post(`api/v1/admin/entry/reject/${params.id}`, params),

    /**
     * POST: transfer team to next stage with groups
     * @param params {
     *           next_stage_id:number|required,
     *           group_id:number|nullable,
     *           tournament_team_ids:array[number][number]
     * }
     * @returns {Promise<AxiosResponse<any>>}
     */
    transferTeamsToNextStage: (params) => adminHttp.post(END_POINT + `/transfer-to-next-stage`, params),
    newTransferTeamsToNextStage: (params) => adminHttp.post('/api/v1/admin/tournament-team/transfer', params),
    mustNewTransferTeamsToNextStage: (groupTeamId, params) => adminHttp.post(`/api/v1/admin/tournament-team/mlbb/transfer-in-group/${ groupTeamId }`, params),

    transferTeamWithMembersToTournament: (params) => adminHttp.post(END_POINT + `/transfer-team`, params),
    newTransferTeamWithMembersToTournament: (params) => adminHttp.post('/api/v1/admin/tournament-team', params),
    addTeamToStage: (params) => adminHttp.post(`/api/v1/admin/tournament-team/add-team/mlbb`, params),

    /**
     * GET: transfer team to next stage with groups
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCountries: () => adminHttp.get('https://core.bigplay.gg/api/country'),

    finishTournament: (id, params) => adminHttp.post(END_POINT + `/set-end-time/${id}`, params),

    /**
     * GET: calculate team rating by tournamentId
     * @returns {Promise<AxiosResponse<any>>}
     */
    calculateRating: (tournamentId) => adminHttp.get(`api/admin/rating/tournament/calculate/${tournamentId}`),

    /**
     * GET: recalculate team rating by tournamentId
     * @returns {Promise<AxiosResponse<any>>}
     */
    recalculateRating: (tournamentId) => adminHttp.get(`api/admin/rating/tournament/recalculate/${tournamentId}`),

    /**
     * GET: generate tournaments
     * @returns {Promise<AxiosResponse<any>>}
     */
    generateTournament: () => adminHttp.get(END_POINT + "/test-tournament"),

    /**
     * GET: get translation mailing
     * @returns {Promise<AxiosResponse<any>>}
     */
    getTranslationMailing: async () => {
        const response = (await adminHttp.get(ENTRY_END_POINT + '/translate-mailing')).data
        delete response.message
        delete response.debug
        return response
    },

    getTeamsByGroup: ({ id }) => adminHttp.get(`api/v1/admin/group/teams/${ id }`),
    getGroupsWithTeamsByMLBB: (roundId) => adminHttp.get(`/api/v1/admin/tournament-team/stage/mlbb/${ roundId }`),
    
    /**
     * POST: generate match
     * @returns {Promise<AxiosResponse<any>>}
     */
    generateOlympicMatches: (groupId, params) => adminHttp.post(NEW_END_POINT + `/match-generate/${groupId}`, params),
    
    /**
     * GET: matches
     * @returns {Promise<AxiosResponse<any>>}
     */
    getOlympicMatches: (groupId, teamId) => adminHttp.post(NEW_END_POINT + `/match-list/${groupId}/${teamId}`),

    hiddenOlympicPair: (params) => adminHttp.post(NEW_END_POINT + `/match-list/hidden-pair`,params),

    setOlympicPairGameTime : (params) => adminHttp.post(NEW_END_POINT + `/match-list/set-game-time/${params.pair_id}`,params),



    /**
     * POST: store winner in olympic
     * @param params {
     *           winner_team_id:number|required,
     *           lose_team_id:number|nullable,
     *           match_id:number|required
     * }
     * @returns {Promise<AxiosResponse<any>>}
     */
    storeMatchResInOlympic: (params) => adminHttp.post(NEW_END_POINT + `/match-list/store`, params),
    
    /**
     * POST: store winner in olympic
     * @param params {
     *           winner_team_id:number|required,
     *           lose_team_id:number|nullable,
     *           team_pair_id:number|required
     * }
     * @returns {Promise<AxiosResponse<any>>}
     */
    storePairResInOlympic: (params) => adminHttp.post(NEW_END_POINT + `/match-list/pair/winner`, params),
    
    /**
     * POST: store match for olympic
     * @param params {
     *           match_name:number|required,
     *           lobby_number:number|nullable,
     *           pair_id:number|required
     * }
     * @returns {Promise<AxiosResponse<any>>}
     */
    storeOlympicMatch: (params) => adminHttp.post(NEW_END_POINT + `/match-list/pair/match/add`, params),
    
    /**
     * DELETE: delete match from olympic
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteOlympicMatch: (matchId) => adminHttp.delete(NEW_END_POINT + `/match-list/pair/match/drop/${matchId}`),
}