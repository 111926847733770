<template>
  <div class="groups-for-sidebar">
    <SidebarBlock header-between>
      <template #header>
        <span class="block-name">Группы</span>
        <button v-if="!isCompletedTournament && formatBySelectedStage !== 'single'"
                class="action-button"
                @click="isShowAddGroupModal = true">
          Создать группу
        </button>
      </template>
      <template #body>
        <Tabs :list="groups"
              id-name="group-item-sidebar-"
              v-model="selectedGroupId"
              :hide-actions="formatBySelectedStage === 'single'"
              :only-view-action="isCompletedTournament"
              @view="viewGroup"
              @change="changeOnGroup"
              @edit="editGroup"
              @delete="deleteGroup"
              tab-name="группу"
        >
          <template #item="{ item: { item: { name, status_code }, isActive } }">
            <div class="tab-label">
              {{ name }}
              <component is="img"
                         :src="getStatusGroupIconData(status_code?.code, isActive).source"
                         v-b-tooltip.hover.top="getStatusGroupIconData(status_code?.code, isActive).tooltip" />
            </div>
          </template>
          <template #pug><h6>Групп нет</h6></template>
        </Tabs>
      </template>
    </SidebarBlock>
    <Modal name="AddGroupTournament" title="Создание группы" v-model="isShowAddGroupModal" :params="{ stage: selectedStage }" @submit="submitCreateGroup"/>
    <Modal name="EditGroupTournament" title="Создание группы" v-model="isShowEditGroupModal" :params="{ id: selectedGroupId }"/>
    <Modal name="DeleteGroupTournament" title="Удаление группы" v-model="isShowDeleteGroupModal" :params="{ group: selectedGroup }"/>
    <Modal name="ReviewGroupTournament" title="Просмотр группы" v-model="isShowReviewGroupModal" :params="{ group: selectedGroup }" />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

// Components
import SidebarBlock from "@/components/Tournament/SidebarBlock.vue";
import Tabs from "@/components/Tournament/Tabs.vue";
import Modal from '@/components/modals/index.vue'

// Dictionary
import { groupStatusesMap } from "@/common/tournamentCommon";

export default {
  name: "GroupsForSidebar",
  components: {
    SidebarBlock,
    Modal,
    Tabs
  },
  data() {
    return {
      isShowAddGroupModal: false,
      isShowReviewGroupModal: false,
      isShowEditGroupModal: false,
      isShowDeleteGroupModal: false
    }
  },
  computed: {
    ...mapGetters('moduleNewTournament', ['groups', 'isCompletedTournament', 'selectedStage', 'selectedGroup', 'formatBySelectedStage']),
    selectedGroupId: {
      get() { return this.$store.state.moduleNewTournament.selectedGroupId },
      set(newValue) { this.setFirstLevelData({ name: 'selectedGroupId', value: newValue }) }
    },
  },
  methods: {
    ...mapMutations('moduleNewTournament', ['setFirstLevelData']),
    viewGroup(id) {
      this.selectedGroupId = id
      this.isShowReviewGroupModal = true
    },
    async changeOnGroup(group) {
      this.selectedGroupId = group.id

      // For another stage types
      if (this.formatBySelectedStage !== 'olympic') {
        // Animation
        const listGroups = document.getElementById('tournament-wrapper')
        const groupItem = document.getElementById(`group-item-workspace-${ group.id }`)
        if (groupItem) {
          listGroups.scrollTo({
            top: groupItem.offsetTop - 200,
            behavior: 'smooth'
          })
          groupItem.classList.add('active')
          setTimeout(() => {
            groupItem.classList.remove('active')
          }, 300)
        }
        return;
      }

      if (this.formatBySelectedStage === 'olympic' || this.formatBySelectedStage === 'bounty') {
        await this.$emit('changeWorkspace', 'LoadTeams')
        await this.$store.dispatch('moduleNewTournament/getTeamsByGroup', { id: this.selectedGroupId })
        await this.$emit('changeWorkspace', undefined)
      }
    },
    editGroup(id) {
      this.selectedGroupId = id
      this.isShowEditGroupModal = true
    },
    deleteGroup(id) {
      this.selectedGroupId = id
      this.isShowDeleteGroupModal = true
    },
    submitCreateGroup() {

    },
    getStatusGroupIconData(codeName, isActive) {
      const statusData = groupStatusesMap.get(codeName)

      if (statusData) return {
        source: statusData[isActive ? 'whiteIcon' : 'blackIcon'],
        tooltip: statusData.text
      }

      return false
    }
  }
}
</script>