import { render, staticRenderFns } from "./BanTeam.vue?vue&type=template&id=4e5b3264&scoped=true"
import script from "./BanTeam.vue?vue&type=script&lang=js"
export * from "./BanTeam.vue?vue&type=script&lang=js"
import style0 from "./BanTeam.vue?vue&type=style&index=0&id=4e5b3264&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e5b3264",
  null
  
)

export default component.exports