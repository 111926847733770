<template>
  <div class="generate-grid-for-stage-tournament-wrapper">
    <h5>Вы действительно хотите сгенерировать таблицу по этапу <b>{{ params?.stage?.name || '' }}</b>?</h5>
<!--    <p class="list-teams-title">Список команд для генерации таблицы: </p>-->
<!--    <ul>-->
<!--      <li v-for="stageTeam in stageTeams">-->
<!--        {{ stageTeam.team.name }}-->
<!--      </li>-->
<!--    </ul>-->
    <h5>Введите количество раундов:</h5>
    <b-input
        v-if="params?.stage?.format_id === 4"
      v-model="roundCount"
      placeholder="Введите количество раундов"
    >
    </b-input>


    <footer>
      <b-button variant="primary" @click="submitForm" :disabled="isLoadingSaveForm">
        {{ isLoadingSaveForm ? 'Генерируем...' : 'Сгенерировать' }}
      </b-button>
    </footer>
    <b-alert v-if="errorText" show class="mt-4" variant="danger">
      {{ errorText }}
    </b-alert>
  </div>
</template>

<script>
import api from "@/api/api";
import { mapState, mapGetters } from "vuex";

export default {
  name: "GenerateGridForStageTournament",
  props: {
    params: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // stageTeams: [],
      isLoadingSaveForm: false,
      errorText: null,
      roundCount:null,
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['tournament', 'selectedRoundId']),
    ...mapGetters('moduleNewTournament', ['rounds', 'groups']),
  },
  methods: {
    async generateGridFirstStage() {
      try {
        this.isLoadingSaveForm = true

        await api.grid.generateFirstStage(this.params.stage.id, {
          ...(this.roundCount != null && { roundCount: this.roundCount })
        });
        await this.$store.dispatch('moduleNewTournament/getTournament', { id: this.tournament.id })
        await this.$store.commit('moduleNewTournament/setFirstLevelData', {
          name: 'selectedRoundId',
          value: this.rounds[0].id
        })
        await this.$store.dispatch('moduleNewTournament/getGroupsWithTeamsByMLBB', { roundId: this.selectedRoundId })
        await this.$store.commit('moduleNewTournament/setFirstLevelData', {
          name: 'selectedGroupId',
          value: this.groups[0].id
        })
        this.$emit('closeModal')

      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSaveForm = false
      }
    },
    async generateGridNextStage() {
      try {
        this.isLoadingSaveForm = true
        await api.grid.generateFirstStage(this.params.stage.id, {
          ...(this.roundCount != null && { roundCount: this.roundCount })
        });

        await this.$store.dispatch('moduleNewTournament/getTournament', { id: this.tournament.id })
        await this.$store.dispatch('moduleNewTournament/getGroupsWithTeamsByMLBB', { roundId: this.selectedRoundId })

        this.$emit('closeModal')

      } catch (e) {
        this.errorText = e.response.data.message
      } finally {
        this.isLoadingSaveForm = false
      }
    },
    async submitForm() {
      if (this.params.stage.prev_stage_id) await this.generateGridNextStage()
      else await this.generateGridFirstStage()
    },
    // async getDefaultData() {
    //   const response = await api.teams.fromStage(this.params.stage.id,{
    //     // page: this.currentPageTeams,
    //   })
    //   this.stageTeams = response?.data?.stage_teams || []
    // }
  },
  created() {
    // this.getDefaultData()
  }
}
</script>

<style scoped lang="scss">
.generate-grid-for-stage-tournament-wrapper {
  h5 {
    font-size: 16px;
    font-weight: 400;
  }
  footer {
    border-top: 1px solid #dee2e6;
    padding-top: 15px;
    margin-top: 15px;
    text-align: right;
  }
  .list-teams-title {
    margin: 0;
  }
  ul {
    padding-left: 25px;

    li {
      list-style-type: decimal;
    }
  }
}
</style>