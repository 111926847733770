<template>
  <div class="have-matches-olympic-wrapper">
    <!--  HEADER  -->
    <header>
      <div class="header-content align-items-center">
        <button v-if="isShowBackButton" class="button-back" @click="$emit('changeWorkspace', undefined)">
          <img :src="AngleLeftIcon" alt=""/>
          Назад
        </button>
        <h2>
          Результаты матчей
        </h2>
      </div>
    </header>
    <div class="team-wrapper">
      {{selectedTeam.name || ''}}
    </div>
    <!--  CONTENT TEMPLATE  -->
    <div v-if="isLoadingOlympicMatches" class="loading-matches">
      <b-spinner/>
    </div>
    <div v-else-if="!pairs.length" class="not-matches-pug">
      <h5>Матчей нет</h5>
    </div>
    <MatchesCardByTeam v-else @changeWorkspace="(name) => $emit('changeWorkspace', name)"/>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import MatchesCardByTeam from "@/components/Tournament/MLBB/components/MatchesCardByTeam.vue";
import AngleLeftIcon from "@/assets/icons/angle-left.svg";

export default {
  name: "HaveMatchesOlympic",
  components: { MatchesCardByTeam },
  data() {
    return {
      AngleLeftIcon,
      isLoadingOlympicMatches: false
    }
  },
  props: {
    team: {
      type: Object,
      default: null
    },
  },
  computed: {
    ...mapState('moduleNewTournament', ['tournament', 'selectedGroupId', 'selectedTeamId', 'pairs', 'selectedTeam']),
    ...mapGetters('moduleNewTournament', ['isCompletedTournament', 'formatBySelectedStage']),
    isShowBackButton() {
      if (this.tournament.game.slug !== 'mlbb') return true
      if (this.tournament.game.slug === 'mlbb' && this.formatBySelectedStage === 'olympic' ||
          this.formatBySelectedStage === 'bounty') return true
      return false
    }
  },
  async mounted() {
    try {
      this.isLoadingOlympicMatches = true
      await this.$store.dispatch('moduleNewTournament/getOlympicMatches', {groupId: this.selectedGroupId, teamId: this.selectedTeamId})
    } finally {

      this.isLoadingOlympicMatches = false
    }
  }
}
</script>

<style scoped lang="scss">
.have-matches-olympic-wrapper {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 560px) {
      display: block;
    }

    h2 {
      font-size: 24px;
      margin: 0 0 0 10px;

      @media screen and (max-width: 800px) {
        font-size: 22px;
      }

      @media screen and (max-width: 500px) {
        font-size: 18px;
      }
    }

    .header-content {
      display: flex;
    }

    .header-action {
      border-radius: 15px;
      font-size: 11px;
      padding: 5px 18px;

      @media screen and (max-width: 800px) {
        font-size: 9px;
      }
    }
  }

  .button-back {
    background: transparent;
    color: #7366ff;
    border: none;
    border-radius: 15px;
    padding: 8px 14px;
    font-size: 14px;
    transition: .2s;

    display: flex;
    align-items: center;

    &:hover {
      background: #dad6ff;
    }

    img {
      margin-right: 7px;
      height: 12px;
    }
  }

  .loading-matches {
    margin: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .not-matches-pug {
    margin: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    h5 {
      margin: 0;
      font-size: 17px;
      font-weight: 400;
    }
  }

  .team-wrapper {
    background: white;
    padding: 20px;
    border-radius: 10px;
    transition: .2s;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 5px;
    box-shadow: 0 0 14px 0 #00000013;
    font-size: 18px;
    font-weight: 500;
  }
}
</style>