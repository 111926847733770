<template>
  <div>
    <div class="">
      <div class="d-flex flex-wrap">
        <div class="mr-2">
          <label>Место</label>
          <b-form-input :value="$props.place" disabled></b-form-input>
        </div>
        <div class="mr-2">
          <label>Приз</label>
          <b-form-input
              @change="onChange($event)"
              :value="$props.amount"
              placeholder="Введите количество очков"
          ></b-form-input>
        </div>
        <div class="mr-2">
          <label>Валюта </label>
          <b-form-select
              :value="currency"
              @change="updatePrizeData('type', $event)"
              :options="currencyOptions"
          ></b-form-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {TournamentAdapterMixin} from "@/mixins/Tournament/TournamentAdapterMixin";

export default {
  name: "AddTournamentPrizeForm",
  mixins: [TournamentAdapterMixin],
  props: {
    index: {
      type: Number,
      default: 0,
    },
    place: {
      type: Number,
      default: 1,
    },
    amount: {
      type: String,
      default: "",
    },
    currency: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      currencyOptions: [
        {value: "dollars", text: "$"},
        {value: "uc", text: "UC"},
      ],
    }
  },
  methods: {
    updatePrizeData(field, value) {
      this.$emit("update-prize", {
        amount: field === "amount" ? Number(value) : this.amount,
        type: field === "type" ? value : this.currency,
      });
    },
    onChange(value) {
      this.changeTournamentPrize({
        id: this.$props.index,
        prize_data: {
          amount: Number.parseInt(value),
        },
      });
    },
  },
};
</script>
