<template>
  <div>
    <Breadcrumbs main="Турниры" title="Команды"></Breadcrumbs>
    <div class="col-md-12">
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <div>
          <SearchInput v-model="searchForEntriesModel" @search="getFilteredEntries()" :placeholder-name="'Поиск заявок'"/>
          <div class="notification-for-search mb-4">Поиск по названию команды</div>
        </div>
        <div class="entries-filter">
          <b-form-group v-if="!isSelectedMlbbGame" class="entries-filter-block" label="Дивизион" label-for="division">
            <b-form-select @change="getEntriesByDivision(selectedDivisionSlug)" v-model="selectedDivisionSlug">
              <b-form-select-option v-for="division in newDivisionsList" :value="division.id">{{ division.name.ru }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group class="entries-filter-block" label="Стадия" label-for="stage">
            <b-form-select @change="getEntriesByStage(selectedStage)" v-model="selectedStage">
              <b-form-select-option v-for="stage in tournamentStages" :value="stage.id">{{ stage.name }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group v-if="!isSelectedMlbbGame" class="entries-filter-block" label="Статус" label-for="status">
            <b-form-select @change="getEntriesByStatus(selectedEntryStatus)" v-model="selectedEntryStatus">
              <b-form-select-option v-for="status in entriesStatuses" :value="status.id">{{ status.code }}</b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="loading-wrapper">
      <b-spinner class="spinner"></b-spinner>
    </div>

    <div v-else class="card">
      <b-table
          responsive
          show-empty
          stacked="md"
          :fields="fields"
          :items="entries"
          :striped="striped"
          :bordered="bordered"
          :current-page=0
          :per-page=10
          key="entries"
      >
        <template #cell(group)="data">
          <div class="text-center rounded border border-primary">
            <div>
              Группа {{ data?.item?.group?.name }}
            </div>
            <div>
              {{ data?.item?.group?.game_time ? moment(data?.item?.group?.game_time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm YYYY.MM.DD') : ''}}
            </div>
            <div>
              {{ data.item?.group?.group_results_count }} / {{ data.item?.group?.slot_count }}
            </div>
          </div>
        </template>
        <template #cell(new_group)="data">
          <div v-if="isShowNewGroup(data)"
               class="text-center rounded border border-primary">
            <div>Группа {{ getFirstStageTeam(data).group_team.group.name }}</div>
            <div>{{ getFirstStageTeam(data).group_team.group.game_time }}</div>
            <div>
              {{ getFirstStageTeam(data).group_team.group.group_results_count }} /
              {{ getFirstStageTeam(data).group_team.group.slot_count }}
            </div>
          </div>
        </template>
        <template #cell(stage)="data">
          <div class="text-center rounded border border-primary">
            <div> Этап {{ data?.item?.stage?.name }} </div>
          </div>
        </template>
        <template #cell(team)="data">
          <div class="text-center">
            <router-link
                :to="{name: 'tournament_entry', params: {tournamentId: $route.params.id, entryId: data.item.id}}"
            >{{ data.item.team.name }}
            </router-link>
          </div>
        </template>
        <template #cell(division)="data">
          <div :class="[getDivisionsStyle(data.item.team.division?.name).style || 'text-center rounded']">
            <div>{{ data.item.team.division?.name.ru }}</div>
            <div>Рейтинг {{ data.item.team?.rating }}</div>
          </div>
        </template>
        <template #cell(language)="data">
          <div class="row justify-content-center p-2">
            {{data?.item?.author?.lang?.code || 'ru'}}
          </div>
        </template>
        <template #cell(status)="data">
          <div class="text-center">
            <!-- Само название статуса -->
            <div :class="getStatus(data.item?.status?.code).style">
              {{ getStatus(data.item?.status?.code).name }}
            </div>

            <!-- Если статус = accepted -->
            <div v-if="data.item?.status?.code === 'accepted'">
              Принято: {{ formatDate(data.item?.accepted_at) }}
            </div>

            <!-- Если статус = rejected -->
            <div v-else-if="data.item?.status?.code === 'rejected'">
              Отклонено: {{ formatDate(data.item?.updated_at) }}
            </div>

            <!-- Иначе (например, pending, draft и т.д.) -->
            <div v-else>
              Создано: {{ formatDate(data.item?.created_at) }}
            </div>
          </div>
        </template>


        <template #cell(contacts)="data">
            <div v-for="account in data.item.author?.social_accounts" :key="'account-' + account.id">
              <span
                  v-if="account.provider === 'discord'"
              >
                <a target="_blank"
                   :href="`https://discordapp.com/users/${account.data.user?.id}`">[{{
                    account?.data?.user?.nickname || account?.data?.nickname
                  }} {{ account.provider }}]</a>
              </span>
               <span
                   v-else-if="account.provider === 'telegram'"
               >
                <a target="_blank" :href="`https://telegram.me/${account.data.username}`">[{{ account.data.username }} {{
                    account.provider
                  }}]</a>
              </span>
            </div>
          </template>
        <template #cell(reject_description)="data">
          <div class="row justify-content-center p-2">
            {{ data.item.reject_description }}
          </div>
        </template>
      </b-table>

      <b-col md="6" class="p-0">
        <b-pagination
            @change="handlePageChange"
            v-model="currentPage"
            :total-rows="total"
            :per-page="10"
            class="my-0"
        ></b-pagination>
      </b-col>
    </div>
  </div>
</template>

<script>
import {TournamentEntriesAdapterMixin} from '@/mixins/Tournament/TournamentEntriesAdapterMixin';
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import {DIVISIONS} from '@/constants/Divisions/divisionsName';
import {DivisionsAdapterMixin} from "@/mixins/Divisions/DivisionsAdapterMixin";
import {NameOfStatus} from '@/constants/Status/StatusesForEntries';
import {StyleOfStatus} from '@/constants/Status/StatusesForEntries';
import {mapGetters} from "vuex";
import moment from 'moment'
import SearchInput from "@/components/SearchInput.vue";
import api from "@/api/api";

export default {
  name: 'TournamentEntries',
  components: {SearchInput},
  mixins: [TournamentEntriesAdapterMixin, ModalAdapterMixin, PaginationHelperMixin, DivisionsAdapterMixin],
  data() {
    return {
      moment,
      pageKey: 'pageNumberEntriesList',
      isLoading: false,
      isSelectedMlbbGame: false,
      fields: [
        {key: 'team', label: 'Команда'},
        {key: 'division', label: 'Дивизион'},
        {key: 'language', label: 'Язык'},
        {key: 'status', label: 'Статус'},
        {key: 'contacts', label: 'Контакты'},
        {key: 'reject_description', label: 'Причина отказа'}
      ],
      striped: true,
      bordered: true,
      tournamentStages: [],
      entriesStatuses: []
    }
  },
  async mounted() {
    Promise.all([
      this.isLoading = true,
      this.recordPageToStorage(this.currentPage, this.pageKey),
      this.indexTournamentEntries({
        pageNumber: this.getCurrentPageNumber(this.pageKey),
        id: this.$route.params.id,
      }),
      this.grabNewDivisions({slug: 'pubg_mobile'}),
      await this.$store.dispatch('moduleNewTournament/getTournament', {
        id: this.$route.params.id,
      }),
      this.newDivisionsList.unshift({id: 0, name: {ru: 'Все'}}),
      this.getTournamentStages(),
      this.getEntriesStatuses()
    ]).then(() => {
      this.isLoading = false
      setTimeout(() => {
        if (this.entries && this.entries.length && this.entries[0].team.game_id !== 3) {
          this.fields.unshift({ key: 'new_group', label: 'Обновленная группа' })
          this.fields.unshift({ key: 'group', label: 'Группа' })
        } else {
          this.fields.unshift({ key: 'stage', label: 'Этап' })
          this.isSelectedMlbbGame = true
        }
      }, 1000)
    })
  },
  methods: {
    async getTournamentStages() {
      const response  = (await api.tournamentStage.tournamentStages(this.$route.params.id)).data
      this.tournamentStages = response.stages || []
      this.tournamentStages.unshift({id: 0, name: 'Все'})
    },
    async getEntriesStatuses() {
      const response  = (await api.tournamentStage.entryStatuses()).data
      this.entriesStatuses = response.statuses || []
      this.entriesStatuses.unshift({id: 0, code: 'Все'})
    },
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey)
      this.indexTournamentEntries({
        pageNumber: pageNumber,
        id: this.$route.params.id,
        ...(this.searchForEntriesModel && {search: this.searchForEntriesModel}),
        ...(this.selectedDivisionSlug && {division: this.selectedDivisionSlug}),
        ...(this.selectedStage && {stage: this.selectedStage}),
        ...(this.selectedEntryStatus && {status: this.selectedEntryStatus}),
      })
    },
    async getFilteredEntries() {
      this.isLoading = true
      await this.indexTournamentEntries({
        id: this.$route.params.id,
        search: this.searchForEntriesModel,
        ...(this.selectedDivisionSlug && {division: this.selectedDivisionSlug}),
        ...(this.selectedStage && {stage: this.selectedStage}),
        ...(this.selectedEntryStatus && {status: this.selectedEntryStatus}),
      })
          .then(() => {
            this.isLoading = false
          })
    },
    getDivisionsStyle(division) {
      return {
        style: `${DIVISIONS[division]}`,
      }
    },
    getStatus(status) {
      return {
        name: `${NameOfStatus[status]}`,
        style: `${StyleOfStatus[status]}`,
      }
    },
    getFirstStageTeam(data) {
      return data.item?.tournament_team?.stage_teams[0]
    },
    isShowNewGroup(data) {
      const firstStageTeam = this.getFirstStageTeam(data)

      const checkData = [
        firstStageTeam,
        firstStageTeam?.group_team,
        (data.item?.group?.name !== firstStageTeam?.group_team?.group?.name)
      ]

      return !checkData.some((data) => !data)
    },
    getDifferenceOfEntryTime(createdDate, acceptedDate, status) {
      const differenceOfDates = moment.duration(moment(acceptedDate, 'YYYY-MM-DD HH:mm:ss').diff(moment(createdDate, 'YYYY-MM-DD HH:mm:ss')))
      return acceptedDate && status === 'accepted' ? `${differenceOfDates.days()}д. ${differenceOfDates.hours()}ч. ${differenceOfDates.minutes()}м.` : ''
    },
    // Метод для форматирования даты
    formatDate(dateStr) {
      if (!dateStr) return '';

      const date = new Date(dateStr);
      // Форматируем дату через тире
      const formattedDate = date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      }).replace(/\//g, '-');  // Заменяем слеши на тире

      const formattedTime = date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit"
      });

      return `${formattedDate} ${formattedTime}`;
    }
  }
}
</script>

<style  scoped lang="scss">
.loading-wrapper {
   min-height: calc(100vh - 180px);
   display: flex;
   align-items: center;
   justify-content: center;

   & > .spinner {
     scale: 2;
   }
 }

    .entries-filter {
      display: flex;
      align-items: center;
      gap: 15px;

      @media screen and (max-width: 575px) {
        margin-bottom: 20px;
      }

      .entries-filter-block::v-deep {
        margin: 0;

        label {
          font-size: 12px;
          color: #aeaeae;
          margin: 0 0 -3px;
        }

        select {
          border-radius: 0;
          background: transparent;
          border: none;
          border-bottom: 1px solid #c3c3c3;
          padding: 2px 13px 2px 7px;
          height: auto;
          color: #666666;
          font-size: 13px;
        }

        input {
          font-size: 13px;
          border: none;
          border-bottom: 1px solid #c3c3c3;
          background: transparent;
          border-radius: 0;
          padding: 2px 13px 2px 7px;
          height: auto;
        }
      }
    }
</style>
