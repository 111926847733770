import adminHttp from "/src/api/adminHttp";

const END_POINT = '/api/admin/team';
const NEW_END_POINT = '/api/v1/admin/team';

export default {
    /**
     * GET: Organizers
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    index: (params) => adminHttp.get(END_POINT, {params: params}),
    uploadTeamLogo(teamId, formData) {
        return adminHttp.post(
            `/api/v1/admin/team/${teamId}/logo`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
    },
    removeTeamLogo: (teamId) => adminHttp.delete(`/api/v1/admin/team/${teamId}/logo`, {
        params: {
            team: teamId
        }
    }),
    fromStage: (id, params) => adminHttp.get(`/api/v1/admin/tournament-team/stage/${id}`, {params}),

    show: (id) => adminHttp.get(END_POINT + `/${id}`),

    store: (params) => adminHttp.post(END_POINT + '/store', params),

    update: (teamId, params) => adminHttp.put(END_POINT + `/${teamId}`, params),

    delete: (id) => adminHttp.delete(END_POINT + `/${id}`),

    updateTeamMemberRole: (teamId, userId, roleId) => adminHttp.post(END_POINT + `/change/member/role/${teamId}/${userId}`, {role_id: roleId}),

    storeTeamMember: (teamId, params) => adminHttp.post(END_POINT + '/store-member' + `/${teamId}`, params),

    showTeamLogs: (teamId) => adminHttp.get(END_POINT + '/log' + `/${teamId}`),

    trashed: (params) => adminHttp.get(END_POINT + '/trashed', {params: params}),

    teamLogs: (params) => adminHttp.get(END_POINT + '/logs' + `/${params.teamId}`, {params: params}),

    teamTournaments: (params) => adminHttp.get(END_POINT + '/tournaments' + `/${params.teamId}`, {params: params}),

    newDeleteTeam: (params) => adminHttp.post('/api/v1/admin/tournament-team/delete', params),

    teamTournamentLogs: (params) => adminHttp.get(NEW_END_POINT + '/entry-logs' + `/${params.teamId}`, {params: params}),

    fromTournament: (tournamentId, params) => adminHttp.get(`/api/v1/admin/tournament/teams/${tournamentId}`, {params}),

    deleteTeamFromGroup: (teamId, params) => adminHttp.delete(`/api/v1/admin/group_teams/${teamId}`, {params: params}),

    deleteTeamFromStage: (teamId, params) => adminHttp.delete(`/api/v1/admin/stage_teams/${teamId}`, {params: params}),

    deleteTeamFromTournament: (teamId, params) => adminHttp.delete(`/api/v1/admin/tournament_teams/${teamId}`, {params: params}),

    // New methods for binding and unbinding pro division
    bindProDivision: (teamId) => adminHttp.patch(`/api/v1/admin/team/${teamId}/bind-pro-division`),

    unbindProDivision: (teamId) => adminHttp.patch(`/api/v1/admin/team/${teamId}/unbind-pro-division`),

    // Upload banner for teams
    uploadBanner: (teamId, formData) => adminHttp.post(`/api/admin/team/${teamId}/banner`, formData),

    deleteBanner: (teamId) => adminHttp.delete(`/api/admin/team/${teamId}/banner`),

    proTeamRegions: () => adminHttp.get(`/api/v1/client/pro-team-region-rating-regions`),
    attachProTeamRegion: (teamId, regionId) => adminHttp.post(`/api/v1/admin/team/${teamId}/attach-pro-team-rating-region/${regionId}`),
    detachProTeamRegion: (teamId) => adminHttp.post(`/api/v1/admin/team/${teamId}/detach-pro-team-rating-region`),
}