<template>
  <div class="match-card-wrapper">
    <header>
      <h5>
        <template v-if="!isEdit">
          {{ matchModel.name }}
        </template>
        <template v-else>
          <span class="title-property">Название матча</span>
          <b-input v-model="matchModel.name" />
        </template>
      </h5>
      <button v-if="!isEdit" class="action" @click="isEdit = true">Редактировать</button>
    </header>
    <div class="body">
      <div class="properties">
        <div v-for="property in properties" class="property">
          <span class="title-property">{{ property.title }}</span>
          <template v-if="isEdit">
            <DatePicker v-if="property.key === 'start_time'"
                        mode="dateTime"
                        v-model="matchModel[property.key]"
                        is24hr
                        :model-config="modelConfig"
                        :popover="{visibility: 'click'}" >
              <template v-slot="{inputValue, inputEvents}">
                <b-form-input :value="inputValue" v-on="inputEvents" required />
              </template>
            </DatePicker>
            <b-input v-else v-model="matchModel[property.key]" />
          </template>
          <span v-else class="value-property">
            <template v-if="property.key === 'start_time'">
              {{ moment(matchModel[property.key], 'YYYY-MM-DD HH:mm:ss').format('HH:mm DD.MM.YYYY') }}
            </template>
            <template v-else>
              {{ matchModel[property.key] }}
            </template>
          </span>
        </div>
        <b-button @click="$emit('delete', index)" class="delete-match" type="submit">Удалить</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar';
import moment from 'moment'

const properties = [
  // {
  //   title: 'Название матча',
  //   key: 'name'
  // },
  {
    title: 'Номер Лобби',
    key: 'lobby_number'
  },
  {
    title: 'Пароль',
    key: 'password'
  },
  {
    title: 'Дата',
    key: 'start_time'
  },
  // {
  //   title: 'Статус',
  //   key: 'status'
  // }
]

export default {
  name: "MatchCard",
  components: {
    DatePicker
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    startEdit: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      properties,
      isEdit: false,
      moment,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm:ss',
      },
    }
  },
  computed: {
    matchModel: {
      get() { return this.value },
      set(newValue) { this.$emit('input', newValue) }
    }
  },
  created() {
    this.isEdit = !!this.startEdit
  }
}
</script>

<style scoped lang="scss">
.match-card-wrapper {
  background: white;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1490196078);
  padding: 20px;
  border-radius: 10px;

  header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  button.action {
    display: block;
    font-size: 11px;
    border-radius: 13px;
    padding: 5px 13px 5px 13px;
    background: white;
    color: #000000bf;
    border: 1px solid #000000bf;
    transition: .2s;

    &:hover {
      background: #000000bf;
      color: white;
    }
  }

  .body {
    padding-top: 20px;
  }

  .delete-match {
    width: fit-content;
    justify-self: flex-end;
    align-self: flex-end;
    height: fit-content;
    border-radius: 15px;
    font-size: 11px;
    padding: 5px 13px 5px 13px;
  }

  h5 {
    margin: 0;
  }

  .properties {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    .property {
      & > * {
        display: block;
      }
    }
  }

  .title-property {
    color: gray;
    font-size: 13px;
    font-weight: 400;
  }

  .value-property {

  }
}
</style>