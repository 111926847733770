<template>
  <div>
    <Breadcrumbs main="Команды" :title='teamsShow.name'/>
    <b-modal centered id="modal-addTeamRating" :title="`Добавить рейтинг для команды ${teamsShow.name}`" v-model="show">
      <div>
        <label for="">Рейтинг</label>
        <b-form-input type="number" v-model="newRating"></b-form-input>
        <label for="" class="mt-2">Описание</label>
        <b-form-textarea v-model="newDescription"></b-form-textarea>
      </div>
      <template #modal-footer>
        <div>
          <b-btn variant="primary" class="mr-3" @click="acceptTeamRating">Подтвердить</b-btn>
          <b-btn variant="danger" @click="show=false">Отмена</b-btn>
        </div>
      </template>
    </b-modal>

    <div class="container">
      <b-card>
        <form>
          <div class="team-header d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-start mb-5">
            <div class="d-flex  flex-column flex-lg-row align-items-center align-items-lg-start mb-4 mb-lg-0">
                <div class="team-wrapper__logo">
                  <img v-if="teamsShow?.logo?.original_url" :src="teamsShow?.logo?.original_url"
                       class="teamLogo upload-team__logo mb-3 mb-lg-0 mr-lg-4"
                       @error="event => event.target.src = TeamImagePug"
                  />
                  <img v-else :src="TeamImagePug"
                       class="teamLogo mb-3 mb-lg-0 mr-lg-4"
                       @error="event => event.target.src = TeamImagePug"
                  />
                  <div class="team-logo__shadow p-absolute"  @click="handleLogoClick">
                    <p>
                      Загрузить
                    </p>
                  </div>
                  <input
                      ref="logoInput"
                      type="file"
                      accept="image/*"
                      style="display: none;"
                      @change="handleLogoSelect"
                  >
                  <b-btn variant="danger" @click="deleteLogo" v-if="teamsShow?.logo?.original_url">
                    Удалить
                  </b-btn>
                </div>
              <div class="team-info ml-lg-3">
                <h1 class="mb-3">{{ teamsShow.name }}</h1>
                <label>Введите новое название</label>
                <ValidationProvider rules="min:2" v-slot="{errors}">
                  <b-form @submit.prevent="updateTeamName({ teamId: $route.params.id, name: teamsShow.name })">
                    <b-input type="text" v-model="teamsShow.name" class="mb-2"></b-input>
                  </b-form>
                  <b-btn @click.prevent="updateTeamName({ teamId: $route.params.id, name: teamsShow.name })" variant="primary" class="mb-2">Сохранить</b-btn>
                  <div class="text-danger">{{ errors[0] }}</div>
                </ValidationProvider>
              </div>
            </div>
            <div class="pro-division-container align-self-center align-self-lg-start">
              <div class="pro-division-badge" :class="{ 'active': isProDivision }">
                <div class="badge-content">
                  <img src="@/assets/icons/crown.svg" alt="Crown Icon" class="crown-icon"/>
                  <span class="pro-division-text">Pro Division</span>
                </div>
              </div>
              <div class="toggle-container">
                <label class="switch">
                  <input
                      type="checkbox"
                      v-model="isProDivision"
                      :disabled="isLoadingToggleProDivision"
                      @change="toggleProDivision"
                  />
                  <span class="slider round"></span>
                </label>
                <span class="toggle-label" :class="{ 'active': isProDivision }">
                  <b-spinner small v-if="isLoadingToggleProDivision"></b-spinner>
                  <span v-else>{{ isProDivision ? 'Активно' : 'Неактивно' }}</span>
                </span>
              </div>
              <div v-if="isProDivision">
                <h6>Регион</h6>
                <hr>
                <template>
                  <b-select
                      :disabled="selectedProRegionId ? true : false"
                      v-model="selectedProRegionId"
                      :options="regionsOptions"
                      class="region-select"
                  />
                </template>
                <b-button class="mt-2" :disabled="selectedProRegionId ? false : true" @click="detachProTeamRegion(teamsShow.id)">Отвязать</b-button>
              </div>
              <p class="pro-division-info mt-2">Профессиональный статус команды для участия в элитных турнирах</p>
            </div>
          </div>
        </form>
        <b-tabs>
          <b-tab title="Общее" active>
            <table class="table mt-5">
              <tr v-if="teamsShow?.currentBans?.length">
                <th class="text-danger">Бан</th>
                <td>
                   <span v-for="(bans, id) in teamsShow.currentBans" :key="bans.id">
                     Команда по {{ bans?.game_name }} находится в бане от {{ bans?.from }} до {{ bans?.to }}
                     Причина: {{ bans?.description }}
                  </span>
                </td>
              </tr>
              <tr v-if="teamsShow?.deleted_at">
                <th class="text-danger">Команда удалена</th>
                <td class="text-danger">
                  {{ teamsShow?.deleted_at ? moment(teamsShow?.deleted_at, 'YYYY-MM-DD HH:mm:ss').format('YYYY.MM.DD HH:mm') : '' }}
                </td>
              </tr>
              <tr>
                <th>Создатель</th>
                <div v-for="member in members" :key="'_member' + member.id">
                  <td v-if="member.role_in_team.code == 'owner'">{{ member.role_in_team.code == null ? 'не указано' : member.username }}</td>
                </div>
              </tr>
              <tr>
                <th>Страна</th>
                <td>{{ teamsShow.country == null ? 'не указано' : teamsShow.country?.name }}</td>
              </tr>
              <tr>
                <th>Дата создания</th>
                <td>{{ teamsShow.created_at }}</td>
              </tr>
              <tr>
                <th>Дивизион</th>
                <td>
                  {{ teamsShow.division?.name }}
                </td>
              </tr>
              <tr>
                <th>Рейтинг</th>
                <td>
                  <div>{{ teamsShow.rating }}</div>
                </td>
              </tr>
              <tr>
                <th>Всего турниров</th>
                <td>{{ teamsShow.tournaments_count }}</td>
              </tr>
              <tr>
                <th>Название игры</th>
                <td>{{ teamsShow.game?.name }}</td>
              </tr>
            </table>
            <div class="mt-5">
              <div class="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center mb-4">
                <h2 class="mb-3 mb-md-0">Участники</h2>
                <router-link :to="{name: 'teams_add_player', params: {id: $route.params.id}}" class="align-self-start align-self-md-center">
                  <b-btn variant="primary">
                    Добавить игрока
                  </b-btn>
                </router-link>
              </div>
              <PubgPlayerTable v-if="teamsShow.game?.slug === 'pubg_mobile'" :tr-class="trClass" :teamMembers="members"/>
              <MlbbPlayerTable v-if="teamsShow.game?.slug === 'mlbb'" :tr-class="trClass" :teamMembers="members"/>
            </div>
          </b-tab>
          <b-tab title="Турниры">
            <b-form>
              <b-table
                  responsive
                  stacked="md"
                  :striped="striped"
                  :bordered="bordered"
                  :fields="tournamentLogsFields"
                  :items="teamTournamentLogs"
                  :current-page=0
                  :per-page=10
                  show-empty
                  class="mt-5">
                <template #cell(name)="data">
                  {{ data.item.tournament.name }}
                </template>
                <template #cell(format)="data">
                  {{ data.item.tournament.format.name }}
                </template>
                <template #cell(compound)="data">
                  <div v-for="member in data.item?.members" :key="'teamMembers-'+member.id">
                    {{ member.username }}
                  </div>
                </template>
                <template #cell(details)="data">
                  <div class="d-flex justify-content-center">
                    <b-button size="lg" @click="showTournDetails(data)" class="px-1 py-0 d-flex" variant="none">
                      <i class="fa fa-arrow-circle-o-down"></i>
                    </b-button>
                  </div>
                </template>
                <template #row-details="tournamentData">
                  <div class="card p-0 m-0" v-if="tournamentData.item?.stage_team?.tournament_team?.stage_teams">
                    <b-table :items="tournamentData.item?.stage_team?.tournament_team?.stage_teams"
                             :fields="tournamentLogFields" striped hover responsive
                             show-empty>
                      <template #cell(stage)="data">
                        {{ data.item?.stage.name }}
                      </template>
                      <template #cell(members)="data">
                        <div v-for="member in data.item?.stage_team_members" :key="'memberId' + member.id">
                          {{ member.user.username }}
                        </div>
                      </template>
                    </b-table>
                  </div>
                  <div v-else class="d-flex justify-content-center">Информация отсутствует</div>
                </template>
                <template #cell(date)="data">
                  {{ data.item?.tournament.start_time }}
                </template>
              </b-table>
              <b-col md="6" class="p-0 mt-5">
                <b-pagination
                    @change="handleTournamentLogsPageChange"
                    v-model="teamTournamentLogsCurrentPage"
                    :total-rows="totalPagesOfTeamTournamentLogs"
                    :per-page="10"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </b-form>
          </b-tab>
          <b-tab title="Матчи">
            <b-form>
              <b-table
                  responsive
                  stacked="md"
                  :striped="striped"
                  :bordered="bordered"
                  :fields="fieldsTournaments"
                  :current-page=0
                  :per-page=10
                  show-empty
                  class="mt-5">
                <template #cell(name)="data">
                </template>
                <template #cell(format)="data">
                  <div></div>
                </template>
                <template #cell(data)="data">
                  <div></div>
                </template>
                <template #cell(compound)="data">
                  <div></div>
                </template>
              </b-table>
            </b-form>
          </b-tab>
          <b-tab title="Логи">
            <div class="text-center">
              <b-form>
                <div>
                  <b-table
                      responsive
                      stacked="md"
                      :striped="striped"
                      :bordered="bordered"
                      :fields="fieldsLogos"
                      :items="teamLogs"
                      show-empty
                      class="mt-5">
                    <template #cell(date)="data">
                      {{ moment(data.item.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm') }}
                    </template>
                    <template #cell(type)="data">
                      {{ data.item.type.code }}
                    </template>
                    <template #cell(reason)="data">
                      <div>{{ getCodeStateMessage(data.item.type.code).description }}
                        {{ data.item?.tournament?.name }}
                        {{ data.item?.data?.new_name }}
                        {{ data.item?.user?.username }}
                      </div>
                      <div v-if="data.item?.data?.tournament?.name">
                        {{ `Турнир: ${data.item?.data?.tournament?.name}` }}
                      </div>
                      <div v-if="data.item?.data?.stage?.name">
                        {{ `Этап: ${data.item?.data?.stage?.name}` }}
                      </div>
                      <div v-if="data.item?.data?.old_stage?.name">
                        {{ `Из этапа: ${data.item?.data?.old_stage?.name}` }}
                      </div>
                      <div v-if="data.item?.data?.new_stage?.name">
                        {{ `В этап: ${data.item?.data?.new_stage?.name}` }}
                      </div>
                      <div v-if="data.item?.data?.group?.name">
                        {{ `Группа: ${data.item?.data?.group?.name}` }}
                      </div>
                      <div v-if="data.item?.data?.reject_description">
                        {{ `Причина: ${data.item?.data?.reject_description}` }}
                      </div>
                      <div v-if="data.item.type.code == 'role-assigned'">
                        {{ getRoleStateMessage(data.item.data.old_role, data.item.data.role).role }}
                      </div>
                    </template>
                    <template #cell(who)="data">
                      {{ data.item?.author?.username }}
                    </template>
                  </b-table>
                </div>
                <b-col md="6" class="p-0 mt-5">
                  <b-pagination
                      @change="logsHandlePageChange"
                      v-model="teamLogsCurrentPage"
                      :total-rows="teamLogsTotal"
                      :per-page="10"
                      class="my-0"
                  ></b-pagination>
                </b-col>
              </b-form>
            </div>
          </b-tab>
          <b-tab title="Настройки">
            <b-table :fields="fieldRating" :items="getTeamCurrentRating" responsive striped class="mt-5">
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(points)="{item}">
                <b-form-input type="number" v-model="item.points" class="w-25"
                              @change="updateCurrentTeamRating(item.points, item.id, item.description)"></b-form-input>
              </template>
              <template #cell(description)="{item}">
                <b-form-textarea v-model="item.description"
                                 @change="updateCurrentTeamRating(item.points, item.id, item.description)"></b-form-textarea>
              </template>
            </b-table>
            <b-btn variant="success" class="mb-3 mt-3" @click.prevent="show = true">Добавить рейтинг</b-btn>
          </b-tab>
        </b-tabs>
        <div class="team-info__form-group mt-4">
          <h2 class="team-info__label mb-4">Баннер команды</h2>
          <div 
            v-if="!bannerPreview && !teamsShow.banner"
            class="team-info__file-drop-area"
            :class="{ 'is-dragover': isDragover }"
            @dragenter.prevent="isDragover = true"
            @dragleave.prevent="isDragover = false"
            @dragover.prevent
            @drop.prevent="handleFileDrop"
            @click="$refs.fileInput.click()"
          >
            <input 
              ref="fileInput"
              type="file"
              @change="handleFileSelect"
              style="display: none;"
              accept="image/*"
            >
            <div class="team-info__file-drop-content">
              <img :src="require('@/assets/icons/image-drop.svg')" alt="Drop image" class="team-info__file-icon">
              <p class="team-info__file-text">{{ isDragover ? 'Перетащите изображение сюда' : 'Перетащите изображение сюда или нажмите, чтобы загрузить' }}</p>
            </div>
          </div>
          <div v-else-if="bannerPreview" class="team-info__preview-container">
            <img :src="bannerPreview" class="team-info__preview-image"/>
            <button @click="uploadBanner" class="team-info__upload-btn">Загрузить</button>
            <button @click="cancelUpload" class="team-info__cancel-btn">Отмена</button>
          </div>
          <div v-else-if="teamsShow.banner" class="team-info__preview-container">
            <img :src="teamsShow.banner.original_url" class="team-info__preview-image"/>
            <button @click="deleteBanner" class="team-info__delete-btn">Удалить</button>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {TeamsAdapterMixin} from "@/mixins/Teams/TeamsAdapterMixin";
import {UsersAdapterMixin} from "@/mixins/Users/UsersAdapterMixin";
import {ROLES} from '@/constants/TeamMember/roles';
import {CODES} from '@/constants/TeamMember/logCodes';
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import moment from 'moment'
import MlbbPlayerTable from "@/components/TeamInfo/MlbbPlayerTable.vue";
import PubgPlayerTable from "@/components/TeamInfo/PubgPlayerTable.vue";
import TeamImagePug from '@/assets/images/pugs/team.png'
import api from "@/api/api";

export default {
  name: 'TeamInfo',
  components: {MlbbPlayerTable, PubgPlayerTable},
  mixins: [TeamsAdapterMixin, UsersAdapterMixin, PaginationHelperMixin],

  created() {
    (async () => {
      try {
        // Загружаем команды и другие данные
        await Promise.all([
          this.showTeams(this.$route.params.id),
          this.showTeamRating(this.$route.params.id),
          this.shortTeamRating(this.$route.params.id),
          this.recordPageToStorage(this.teamTournamentLogsCurrentPage, this.teamTournamentLogsPageKey),
          api.teams.teamTournamentLogs({
            teamId: this.$route.params.id,
            page: this.getCurrentPageNumber(this.teamTournamentLogsPageKey),
          }),
          this.recordPageToStorage(this.teamLogsCurrentPage, this.pageKeyLogs),
          this.teamLogsShow({
            teamId: this.$route.params.id,
            pageNumber: this.getCurrentPageNumber(this.pageKeyLogs),
          })
        ]);

        // Устанавливаем турниры
        const tournamentLogs = (await api.teams.teamTournamentLogs({
          teamId: this.$route.params.id,
          page: this.teamTournamentLogsCurrentPage
        })).data;
        this.teamTournamentLogs = tournamentLogs.entry_logs || [];
        this.totalPagesOfTeamTournamentLogs = tournamentLogs.pagination.total;

        // Проверяем division.id после загрузки данных
        if (this.teamsShow && this.teamsShow.division) {
          this.isProDivision = this.teamsShow.division.id === 5;
        } else {
          this.isProDivision = false; // Убедимся, что есть fallback
        }

        // Загружаем список регионов
        this.proTeamsRegionsList = await this.fetchProTeamRegions();
        this.regionsOptions = [
          { text: 'Выберите регион', value: null, disabled: true }, // Значение по умолчанию
          ...this.proTeamsRegionsList.map((region) => ({
            text: region.name,
            value: region.id
          }))
        ];

        // Проверяем, привязан ли регион
        if (this.teamsShow.hasOwnProperty('proTeamRegionRating')) {
          this.selectedProRegionId = this.teamsShow.proTeamRegionRating.proTeamRatingRegion.id;
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    })();
  },
  data() {
    return {
      TeamImagePug,
      moment,
      pageKeyLogs: 'pageNumberTeamLogs',
      teamTournamentLogsPageKey: 'teamTournamentLogsPageNumber',
      teamTournamentLogs: [],
      totalPagesOfTeamTournamentLogs: 1,
      teamTournamentLogsCurrentPage: 1,
      fields: [
        {key: 'id', label: '№',},
        {key: 'nick', label: 'Nick',},
        {key: 'pubg_nick', label: 'Pubg nick'},
        {key: 'pubg_id', label: 'Pubg id',},
        {key: 'status', label: 'Cтатус',},
        {key: 'country', label: 'Страна',},
        {key: 'socials', label: 'Социальные сети',},
        {key: 'role', label: 'Роль',},
        {key: 'inspect', label: 'Просмотр',},
      ],
      tournamentLogsFields: [
        {key: 'id', label: '№',},
        {key: 'name', label: 'Название',},
        {key: 'format', label: 'Формат'},
        {key: 'details', label: 'Детали',},
        {key: 'date', label: 'Дата турнира',},
      ],
      fieldsTournaments: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Название', sortable: true },
        { key: 'date', label: 'Дата', sortable: true },
        { key: 'format', label: 'Формат', sortable: false },
      ],
      fieldsLogos: [
        {key: 'id', label: '№',},
        {key: 'date', label: 'Дата и время',},
        {key: 'type', label: 'Тип'},
        {key: 'reason', label: 'Описание',},
        {key: 'who', label: 'Кто',},
      ],
      fieldRating: [
        {key: 'index', label: '#'},
        {key: 'points', label: 'Рейтинг'},
        {key: 'description', label: 'Описание'},
        {key: 'created_at', label: 'Дата создания'},
        {key: 'id', label: 'ID'}
      ],
      tournamentLogFields: [
        {key: 'id', label: 'id', sortable: true},
        {key: 'stage', label: 'Стадия', sortable: true},
        {key: 'members', label: 'Состав', sortable: true},
      ],
      striped: true,
      bordered: true,
      totalRows: 10,
      show: false,
      isProDivision: false,
      isLoadingToggleProDivision: false,
      // Banner upload
      isDragover: false,
      bannerFile: null,
      bannerPreview: null,
      proTeamsRegionsList: [],
      regionsOptions: [],
      selectedProRegionId: null,
    }
  },
  watch: {
    selectedProRegionId(newRegionId, oldRegionId) {

      if (newRegionId && !this.teamsShow.hasOwnProperty('proTeamRegionRating')) {
        this.attachProTeamRegion(this.teamsShow.id, newRegionId);
      }
    }
  },
  methods: {
    handleLogoClick() {
      // Открываем окно выбора файла
      this.$refs.logoInput.click();
    },
    handleLogoSelect(event) {
      const file = event.target.files[0];
      // Проверяем, что файл есть и что это изображение
      if (file && file.type.startsWith('image/')) {
        this.uploadLogo(file);
      }
    },
    async deleteLogo(){
      try {
        await api.teams.removeTeamLogo(this.$route.params.id);
        await this.showTeams(this.$route.params.id);

        this.$bvToast.toast('Логотип успешно удалён!', {
          title: 'Успех',
          variant: 'success',
          solid: true
        });
      }catch(error) {
        console.error('Ошибка при удалении логотипа:', error);
        this.$bvToast.toast('Не удалось удалить логотип', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true
        });
      }
    },
    async uploadLogo(file) {
      const formData = new FormData();
      formData.append('logo', file);
      try {
        await api.teams.uploadTeamLogo(this.$route.params.id, formData);
        // Обновляем данные команды, чтобы сразу увидеть новое лого
        await this.showTeams(this.$route.params.id);
        this.$bvToast.toast('Логотип успешно загружен!', {
          title: 'Успех',
          variant: 'success',
          solid: true
        });
      }catch(error) {
        if(error.response.status === 413) {
          this.$bvToast.toast('Слишком большой размер файла', {
            title: 'Ошибка',
            variant: 'danger',
            solid: true
          });
          return;
        }
        this.$bvToast.toast('Не удалось загрузить логотип', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true
        });
      }finally {
        this.$refs.logoInput.value = '';
      }
    },
    acceptTeamRating() {
      this.addTeamRating({
        team_id: this.$route.params.id,
        points: this.newRating,
        description: this.newDescription
      }).then(() => {
        this.show = false
        this.shortTeamRating(this.$route.params.id)
      })
    },
    updateCurrentTeamRating(points, id, description) {
      this.updateTeamRating({points: points, id: id, team_id: this.$route.params.id, description: description})
    },
    getRoleStateMessage(old_role, role) {
      return { role: `Был ${ROLES[old_role]} - Стал ${ROLES[role]}` }
    },
    getCodeStateMessage(code) {
      return { description: `${CODES[code]}` }
    },
    trClass: rowData => rowData?.currentBans?.length && 'table-danger',
    logsHandlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKeyLogs)
      this.teamLogsShow({
        teamId: this.$route.params.id,
        pageNumber: pageNumber,
      })
    },
    async handleTournamentLogsPageChange(pageNumber) {
      const params = {page: pageNumber, teamId: this.$route.params.id}
      this.recordPageToStorage(pageNumber, this.teamTournamentLogsPageKey)
      const response = await api.teams.teamTournamentLogs(params)
      this.teamTournamentLogs = response.data.entry_logs || []
    },
    showTournDetails(data) {
      data.toggleDetails();
    },
    async toggleProDivision() {
      this.isLoadingToggleProDivision = true; // Устанавливаем состояние загрузки
      try {
        await (this.isProDivision
            ? api.teams.bindProDivision
            : api.teams.unbindProDivision)(this.$route.params.id);

        if (!this.isProDivision && this.selectedProRegionId) {
          await this.detachProTeamRegion(this.teamsShow.id);
        }
      } catch (error) {
        console.error('Error toggling pro division:', error);
        this.isProDivision = !this.isProDivision; // Возвращаем предыдущее состояние при ошибке
        this.$bvToast.toast('Не удалось изменить статус Pro Division', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true,
        });
      } finally {
        this.isLoadingToggleProDivision = false; // Сбрасываем состояние загрузки
      }
    },
    handleFileSelect(event) {
      const file = event.target.files[0];
      this.processFile(file);
    },

    handleFileDrop(event) {
      this.isDragover = false;
      const file = event.dataTransfer.files[0];
      this.processFile(file);
    },
    processFile(file) {
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.bannerPreview = e.target.result;
          this.bannerFile = file;
        };
        reader.readAsDataURL(file);
      }
    },
    async uploadBanner() {
      if (!this.bannerFile) return;

      const formData = new FormData();
      formData.append('file', this.bannerFile);
      formData.append('_method', 'patch');

      try {
        await api.teams.uploadBanner(this.$route.params.id, formData);
        // Refresh the team data to get the new banner
        await this.showTeams(this.$route.params.id);
        this.bannerPreview = null;
        this.bannerFile = null;
        this.$bvToast.toast('Баннер успешно загружен', {
          title: 'Успех',
          variant: 'success',
          solid: true
        });
      } catch (error) {
        console.error('Error uploading banner:', error);
        this.$bvToast.toast('Не удалось загрузить баннер', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true
        });
      }
    },
    cancelUpload() {
      this.bannerPreview = null;
      this.bannerFile = null;
    },
    async deleteBanner() {
      try {
        await api.teams.deleteBanner(this.$route.params.id);
        // Refresh the team data to remove the banner
        await this.showTeams(this.$route.params.id);
        this.$bvToast.toast('Баннер успешно удален', {
          title: 'Успех',
          variant: 'success',
          solid: true
        });
      } catch (error) {
        console.error('Error deleting banner:', error);
        this.$bvToast.toast('Не удалось удалить баннер', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true
        });
      }
    },
    async fetchProTeamRegions() {
      try {
        const response = await api.teams.proTeamRegions();
        if (response.status === 200) {
          return response.data.data;
        } else {
          console.error(`Error: Received status ${response.status}`);
          return [];
        }
      } catch (error) {
        console.error('Error fetching pro team regions:', error);
        return [];
      }
    },
    async detachProTeamRegion(teamId) {
      try {
        const response = await api.teams.detachProTeamRegion(teamId);
        if (response.status === 204) {
          this.clearRegion()
          this.$bvToast.toast('Регион успешно отвязан', {
            title: 'Успех',
            variant: 'success',
            solid: true
          });
        } else {
          console.error(`Ошибка: получен статус ${response.status}`);
          this.$bvToast.toast('Не удалось отвязать регион', {
            title: 'Ошибка',
            variant: 'danger',
            solid: true
          });
        }
      } catch (error) {
        console.error('Ошибка отвязки региона:', error);
        this.$bvToast.toast('Не удалось отвязать регион', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true
        });
      }
    },
    async attachProTeamRegion(teamId, regionId) {
      try {
        const response = await api.teams.attachProTeamRegion(teamId, regionId);
        if (response.status === 204) {
          this.$bvToast.toast('Регион успешно привязан', {
            title: 'Успех',
            variant: 'success',
            solid: true
          });
        } else {
          this.$bvToast.toast('Не удалось привязать регион', {
            title: 'Ошибка',
            variant: 'danger',
            solid: true
          });
        }
      } catch (error) {
        console.error('Ошибка привязки региона:', error);
        this.$bvToast.toast('Не удалось привязать регион', {
          title: 'Ошибка',
          variant: 'danger',
          solid: true
        });
      }
    },
    clearRegion() {
      this.selectedProRegionId = null;
    },
  },
}
</script>

<style scoped>
.upload-team__logo {
  width: 180px;
  height: 180px;
}
.team-wrapper__logo {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.team-wrapper__logo:hover .team-logo__shadow {
  transition: 200ms ease-in-out;
  opacity: 1;
  cursor: pointer;
}
.team-logo__shadow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  transition: opacity 200ms ease-in-out; /* Плавный переход для opacity */
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
}
/* Pro badge */
.toggle-label {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
  transition: color 0.3s ease;
}
.pro-division-container {
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  max-width: 300px;
}
.pro-division-container:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
.toggle-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  background-color: #f0f0f0;
  border-radius: 34px;
  padding: 10px;
  transition: background-color 0.3s ease;
}
.pro-division-badge {
  background: linear-gradient(135deg, #e0e0e0 0%, #f5f5f5 100%);
  border-radius: 50px;
  padding: 10px 20px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
  transition: all 0.3s ease;
}

.pro-division-badge.active {
  background: linear-gradient(135deg, #ffd700 0%, #ffec8b 100%);
}
.badge-content {
  display: flex;
  align-items: center;
}
/* Rest of styles */
.crown-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.pro-division-text {
  display: inline-block;
  line-height: 24px;
  font-weight: 500;
}
.teamLogo {
  max-width: 180px;
  max-height: 180px;
  object-fit: cover;
  border-radius: 50%
}

/* Toggle switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin: 0;
  flex-shrink: 0;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}
input:checked + .slider { background-color: #7366FF; }
input:checked + .slider:before { transform: translateX(26px); }
.slider.round { border-radius: 34px; }
.slider.round:before { border-radius: 50%; }

/* Banner upload */
.team-info__file-drop-area {
  width: 100%;
  height: 180px;
  border: 3px dashed #ddd;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
}
.team-info__file-drop-area.is-dragover {
  background-color: rgba(0, 0, 0, 0.05);
  border-color: #999;
}
.team-info__file-drop-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.team-info__file-text {
  color: #777777;
  max-width: 200px;
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
}
.team-info__file-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 10px;
  color: black;
}
.team-info__preview-container {
  position: relative;
  display: inline-block;
}
.team-info__preview-image {
  max-width: 100%;
  margin-top: 10px;
  border-radius: 4px;
}
.team-info__delete-btn,
.team-info__upload-btn,
.team-info__cancel-btn {
  position: absolute;
  top: 20px;
  right: 10px;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.team-info__delete-btn { background-color: rgba(255, 49, 49, 0.8); }
.team-info__delete-btn:hover { background-color: rgba(202, 40, 40, 0.8); }
.team-info__upload-btn {
  background-color: rgba(0, 123, 255, 0.8);
  right: 80px;
}
.team-info__upload-btn:hover { background-color: rgba(0, 98, 204, 0.8); }
.team-info__cancel-btn { background-color: rgba(108, 117, 125, 0.8); }
.team-info__cancel-btn:hover { background-color: rgba(90, 98, 104, 0.8); }
</style>