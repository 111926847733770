<template>
  <div class="add-team-workspace-wrapper">
    <!--  HEADER  -->
    <header>
      <button v-if="isShowBackButton" class="button-back" @click="$emit('changeWorkspace', undefined)">
        <img :src="AngleLeftIcon" alt="" />
        Назад
      </button>
      <h2>
        {{ headerTitle }}
      </h2>
    </header>
    <!--  ACTIONS BLOCK  -->
    <div class="card-wrapper">
      <div class="main-actions">
        <Snippets v-model="selectedTypeTeamList" :list="typesTeamList" />
        <div class="search-teams-wrapper">
          <img class="search-icon" :src="SearchIcon" alt="" />
          <!--        v-b-tooltip.hover.top="'Поиск доступен по названием и id команд'" -->
          <b-input class="custom-input"
                   v-model="searchText"
                   @input="getTeamsBySearch"
                   v-b-tooltip.hover.top="'Поиск доступен по названием команд'"
                   placeholder="Поиск команды" />
        </div>
      </div>
      <div class="teams-count">Количество команд: {{teamsCount}}</div>
    </div>
    <!--  CONTENT TEMPLATE  -->
    <div v-if="isLoadingTeams" class="loading-teams">
      <b-spinner />
    </div>
    <div v-else-if="notHasTeamsByCurrentType" class="not-teams-pug">
      <h5>Команд нет</h5>
    </div>
    <template v-else>
      <template v-if="selectedTypeTeamList === 'all'">
<!--        <span v-for="(team, indexT) in teams">{{ team.name }} / {{ indexT }}</span>-->
        <AddTeamCard v-for="(team, indexT) in teams"
                     :team="team"
                     :key="indexT + 'team-card-' + team.id" />
      </template>
      <template v-if="selectedTypeTeamList === 'waiting'">
        <AddWaitingTeamCard v-for="(team, indexT) in waitingTeams"
                            @clearTeamFromStorage="clearTeamFromStorage"
                            listName="waitingTeams"
                            :team="team"
                            :key="indexT + 'waiting-team-card-' + team.id" />
      </template>
      <template v-if="selectedTypeTeamList === 'accepted'">
        <AddWaitingTeamCard v-for="(team, indexT) in acceptedTeams"
                            :key="indexT + 'accepted-team-card-' + team.id"
                            @clearTeamFromStorage="clearTeamFromStorage"
                            isHideAdd
                            listName="acceptedTeams"
                            :team="team" />
      </template>
      <template v-if="selectedTypeTeamList === 'tournament-teams'">
        <AddTournamentTeamCard v-for="(team, indexT) in tournamentTeams"
                            :team="team"
                            isHideAdd
                            listName="tournamentTeams"
                            @clearTeamFromStorage="clearTeamFromStorage"
                            :key="indexT + 'tournament-team-card-' + team.id"/>
      </template>
      <infinite-loading v-if="isShowInfinityLoading"
                        @infinite="(state) => getByInfinityLoading({ page: ++currentPageTeams }, state)">
        <div slot="no-results">Больше нет команд</div>
        <div slot="no-more">Больше нет команд</div>
      </infinite-loading>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import api from '/src/api/api'
import lodash from 'lodash'

import AngleLeftIcon from '@/assets/icons/angle-left.svg'
import SearchIcon from '@/assets/icons/search.svg'

import AddTeamCard from '@/components/Tournament/components/AddTeamCard.vue'
import AddWaitingTeamCard from "@/components/Tournament/components/AddWaitingTeamCard.vue";
import AddTournamentTeamCard from "@/components/Tournament/components/AddTournamentTeamCard.vue";

import Snippets from "@/components/Tournament/Snippets.vue";
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: "AddTeamWorkspace",
  components: {
    AddTeamCard,
    InfiniteLoading,
    Snippets,
    AddWaitingTeamCard,
    AddTournamentTeamCard,
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedStageId', 'selectedGroupId', 'selectedRoundId', 'tournament', 'groupsWithTeamsByMLBB']),
    ...mapGetters('moduleNewTournament', ['formatBySelectedStage']),
    isOnlySelectedMLBBStage() {
      const isMLBBGame = ['mlbb'].includes(this.tournament.game.slug)
      const isOnlySelectedStage = this.selectedStageId && !this.selectedGroupId && !this.selectedRoundId
      return isOnlySelectedStage && isMLBBGame
    },
    typesTeamList() {
      return [
        {
          key: 'all',
          title: 'Все',
          method: this.getTeams,
          searchMethod: this.getTeamsBySearchByAll,
          listName: 'teams'
        },
        {
          key: 'tournament-teams',
          title: 'В турнире',
          method: this.getTournamentTeams,
          searchMethod: this.getTeamsBySearchByTournament,
          listName: 'tournamentTeams'
        },
        ...(
            this.isOnlySelectedMLBBStage &&
            [{
              key: 'accepted',
              title: 'Принятые в этап',
              method: this.getAcceptedTeams,
              listName: 'acceptedTeams',
              searchMethod: this.getTeamsBySearchByAccepted,
            }]
            ||
            [{
              key: 'waiting',
              title: 'В этапе',
              method: this.getWaitingTeams,
              searchMethod: this.getTeamsBySearchByStage,
              listName: 'waitingTeams'
            }]
        ),
      ]
    },
    typesTeamListMap() {
      return this.typesTeamList.reduce((mapState, type) => mapState.set(type.key, type), new Map())
    },
    notHasTeamsByCurrentType() {
      return !(this[this.typesTeamListMap.get(this.selectedTypeTeamList).listName].length)
    },
    headerTitle() {
      if (this.isOnlySelectedMLBBStage) return 'Менеджемент команд'
      return 'Добавление команды'
    },
    isShowBackButton() {
      if(!this.isOnlySelectedMLBBStage) return true
      if (this.tournament.game.slug !== 'mlbb') return true
      if (this.tournament.game.slug === 'mlbb' && this.formatBySelectedStage === 'olympic' ||
          this.formatBySelectedStage === 'bounty') return true
      return false
    },
    teamsCount() {
      if(this.selectedTypeTeamList === 'all') { return this.allTeamsCount}
      if(this.selectedTypeTeamList === 'waiting') { return this.waitingTeamsCount}
      if(this.selectedTypeTeamList === 'accepted') { return this.acceptedTeamsCount}
      if(this.selectedTypeTeamList === 'tournament-teams') { return this.tournamentTeamsCount}
      return false
    },
  },
  data() {
    return {
      // Icons
      AngleLeftIcon,
      SearchIcon,

      // Toggles
      isLoadingTeams: false,
      isShowInfinityLoading: false,

      // Models
      searchText: '',
      teams: [],
      waitingTeams: [],
      acceptedTeams: [],
      tournamentTeams: [],
      currentPageTeams: 1,
      selectedTypeTeamList: 'all',
      allTeamsCount: null,
      waitingTeamsCount: null,
      acceptedTeamsCount: null,
      tournamentTeamsCount: null
    }
  },
  methods: {
    async getTeams(params, $state) {
      this.currentPageTeams = params?.page || 1
      const response = await api.teams.index({
        'filter[game_id]': this.tournament.game.id,
        page: this.currentPageTeams,
      })
      this.teams = response?.data?.teams ? [...this.teams, ...response?.data?.teams ] : []
      this.allTeamsCount = response?.data?.pagination.total || 0
      if ($state && response.data.teams.length) $state.loaded()
      if ($state && !response.data.teams.length) $state.complete()
    },
    async getTeamsBySearchByAll() {
      this.isShowInfinityLoading = false
      this.currentPageTeams = 1
      this.isLoadingTeams = true
      const responseByName = await api.teams.index({
        page: 1,
        // 'filter[game_id]': this.tournament.game.id,
        'search': this.searchText,
      })
      this.teams = [
        ...responseByName?.data?.teams,
      ]
      this.isLoadingTeams = false
      if (!this.searchText) this.isShowInfinityLoading = true
    },
    async getWaitingTeams(params, $state) {
      this.currentPageTeams = params?.page || 1
      const response = await api.teams.fromStage(this.selectedStageId,{
        page: this.currentPageTeams,
      })
      this.waitingTeams = response?.data?.stage_teams ? [
          ...this.waitingTeams,
          ...response?.data?.stage_teams.reduce((state, { team, id, stage_team_members, tournament_team_id }) => {
              state.push({
                ...team,
                stage_team_id: id,
                captain: team.team_owner[0],
                stageTeamMembers: stage_team_members,
                tournament_team_id
              })
              return state
          }, [])
      ] : []
      this.waitingTeamsCount = response?.data?.pagination.total || 0
      if ($state && response.data.stage_teams.length) $state.loaded()
      if ($state && !response.data.stage_teams.length) $state.complete()
    },
    async getAcceptedTeams(params, $state) {
      this.currentPageTeams = params?.page || 1
      const response = (await api.teams.fromStage(this.selectedStageId, {
        page: this.currentPageTeams,
      })).data

      const teams = response.stage_teams.map((stageTeam) => ({
        ...stageTeam.team,
        stage_team_id: stageTeam.id,
        tournament_team_id: stageTeam.tournament_team_id,
        stageTeamMembers: stageTeam.stage_team_members,
        captain: stageTeam.team?.team_owner?.length ? stageTeam.team?.team_owner[0] : null
      }))
      this.acceptedTeams = teams ? [...this.acceptedTeams, ...teams ] : []
      this.acceptedTeamsCount = response?.pagination.total
      if ($state && teams.length) $state.loaded()
      if ($state && !teams.length) $state.complete()
    },
    async getTeamsBySearchByStage() {
      this.isShowInfinityLoading = false
      this.currentPageTeams = 1
      this.isLoadingTeams = true
      const responseByName = await api.teams.fromStage(this.selectedStageId,{
        page: 1,
        'search': this.searchText,
      })
      this.waitingTeams = [
        ...(responseByName?.data?.stage_teams ? [
          ...responseByName?.data?.stage_teams.reduce((state, { team, id, stage_team_members, tournament_team_id }) => {
            state.push({
              ...team,
              stage_team_id: id,
              captain: team.team_owner[0],
              stageTeamMembers: stage_team_members,
              tournament_team_id
            })
            return state
          }, [])
        ] : []),
      ]
      this.isLoadingTeams = false
      if (!this.searchText) this.isShowInfinityLoading = true
    },
    async getTeamsBySearchByAccepted() {
      this.isShowInfinityLoading = false
      this.currentPageTeams = 1
      this.isLoadingTeams = true
      const responseByName = await api.teams.fromStage(this.selectedStageId,{
        page: 1,
        // 'filter[game_id]': this.tournament.game.id,
        // 'filter[tournamentEntries.stage_id]': this.selectedStageId,
        'search': this.searchText,
      })

      const teams = responseByName.data.stage_teams.map((stageTeam) => ({
        ...stageTeam.team,
        stage_team_id: stageTeam.id,
        tournament_team_id: stageTeam.tournament_team_id,
        stageTeamMembers: stageTeam.stage_team_members,
        captain: stageTeam.team?.team_owner?.length ? stageTeam.team?.team_owner[0] : null
      }))

      this.acceptedTeams = [
        ...teams,
      ]
      this.isLoadingTeams = false
      if (!this.searchText) this.isShowInfinityLoading = true
    },
    getTeamsBySearch: lodash.debounce(async function () {
      await this.typesTeamListMap.get(this.selectedTypeTeamList).searchMethod()
    }, 1000),
    async getByInfinityLoading(params, state) {
      await this.typesTeamListMap.get(this.selectedTypeTeamList).method(params, state)
    },
    clearTeamFromStorage({ team, listName }) {
      const teamIndex = this[listName].findIndex(({ id }) => id === team.id)
      this[listName].splice(teamIndex, 1)
    },

    // new
    async getTournamentTeams(params, $state) {
      this.currentPageTeams = params?.page || 1

      const response = (await api.teams.fromTournament(this.tournament.id, {
        page: this.currentPageTeams,
      })).data

      const teams = response.teams.map((team) => ({
        ...team,
        tournament_team_id: team.tournament_team.id
      }))

      this.tournamentTeams = teams ? [...this.tournamentTeams, ...teams ] : []
      this.tournamentTeamsCount = response?.pagination.total

      if ($state && teams.length) $state.loaded()
      if ($state && !teams.length) $state.complete()
    },
    async getTeamsBySearchByTournament() {
      this.isShowInfinityLoading = false
      this.currentPageTeams = 1
      this.isLoadingTeams = true
      const responseByName = await api.teams.fromTournament(this.tournament.id, {
        page: 1,
        'search': this.searchText,
      })
      const teams = responseByName.data.teams.map((team) => ({
        ...team,
        tournament_team_id: team.tournament_team.id
      }))
      this.tournamentTeams = [
        ...teams,
      ]
      this.isLoadingTeams = false
      if (!this.searchText) this.isShowInfinityLoading = true
    },
  },
  watch: {
    selectedTypeTeamList: {
      immediate: true,
      async handler(newType) {
        this.isShowInfinityLoading = false
        this.isLoadingTeams = true
        this[this.typesTeamListMap.get(newType).listName] = []
        if (!this.groupsWithTeamsByMLBB) await this.$store.dispatch('moduleNewTournament/getGroupsWithTeamsByMLBB', {})
        await this.typesTeamListMap.get(newType).method()
        this.isLoadingTeams = false
        this.isShowInfinityLoading = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.add-team-workspace-wrapper {

  header {
    display: flex;
    align-items: center;
    justify-content: left;

    @media screen and (max-width: 560px) {
      display: block;
    }

    h2 {
      font-size: 24px;
      margin: 0 0 0 10px;

      @media screen and (max-width: 800px) {
        font-size: 22px;
      }

      @media screen and (max-width: 500px) {
        font-size: 18px;
      }
    }
  }

  .button-back {
    background: transparent;
    color: #7366ff;
    border: none;
    border-radius: 15px;
    padding: 8px 14px;
    font-size: 14px;
    transition: .2s;

    display: flex;
    align-items: center;

    &:hover {
      background: #dad6ff;
    }

    img {
      margin-right: 7px;
      height: 12px;
    }
  }

  .loading-teams {
    margin: 100px 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .not-teams-pug {
    margin: 100px 0;

    display: flex;
    align-items: center;
    justify-content: center;

    h5 {
      margin: 0;
      font-size: 17px;
      font-weight: 400;
    }
  }

  .card-wrapper {
    background: white;
    padding: 20px;
    border-radius: 10px;
    transition: .2s;
    margin: 20px 0;
    position: sticky;
    top: 5px;
    left: 0;
    z-index: 6;
    box-shadow: 0 0 14px 0 #00000013;

    .main-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;

      @media screen and (max-width: 1100px) {
        justify-content: center;
      }
    }

    .teams-count {
      background-color: white;
      border-radius: 0.25rem;
      padding: 0.375rem 0.75rem;
      border: 1px solid #ced4da;
      color: #707070;
      font-size: 13px;
      width: fit-content;
      margin: 10px 0 0 auto;

      @media screen and (max-width: 1100px) {
        margin: 10px auto auto auto;
      }
    }
  }

  .search-teams-wrapper {
    width: 200px;
    position: relative;

    .search-icon {
      height: 15px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
    }

    .custom-input {
      padding-left: 32px;

      @media screen and (max-width: 500px) {
        font-size: 14px;
      }
    }
  }
}
</style>