<template>
  <div class="add-group-tournament-wrapper">
    <b-form @submit="submitForm">
      <b-form-group label="Название группы" label-for="name">
        <b-form-input v-model="formModel.name" id="name" type="text" required />
      </b-form-group>
      <div class="grid-mini-inputs" :class="[ formatBySelectedStage === 'double' && 'one-column' ]">
        <b-form-group v-if="formatBySelectedStage !== 'double'" label="Слот" label-for="slot_count">
          <b-form-input v-model="formModel.slot_count" id="slot_count" type="number" required />
        </b-form-group>
        <b-form-group label="Статус" label-for="status">
          <b-form-select v-model="formModel.status" id="status" :options="statuses" required />
        </b-form-group>
      </div>
      <b-form-group label="Дата" label-for="game_time">
        <DatePicker mode="dateTime" v-model="formModel.game_time" is24hr :model-config="modelConfig" :popover="{visibility: 'click'}">
          <template v-slot="{inputValue, inputEvents}">
            <b-form-input :value="inputValue" v-on="inputEvents" :required="formatBySelectedStage !== 'double'" />
          </template>
        </DatePicker>
      </b-form-group>
      <b-form-group label-for="confirm_time">
        <div class="confirm-time">
          <div>
            <label for="start_date_picker">Дата начала подтверждения</label>
            <DatePicker mode="dateTime" v-model="formModel.approve_start_time" is24hr :model-config="modelConfigForConfirmTime" :popover="{visibility: 'click'}">
              <template v-slot="{inputValue, inputEvents}">
                <b-form-input :value="inputValue" v-on="inputEvents" required/>
              </template>
            </DatePicker>
          </div>
          <div>
            <label for="start_date_picker">Дата окончания подтверждения</label>
            <DatePicker mode="dateTime" v-model="formModel.approve_end_time" is24hr :model-config="modelConfigForConfirmTime" :popover="{visibility: 'click'}">
              <template v-slot="{inputValue, inputEvents}">
                <b-form-input :value="inputValue" v-on="inputEvents" required/>
              </template>
            </DatePicker>
          </div>
        </div>
      </b-form-group>
      <b-form-group v-if="selectedStage?.format_id === 2" label="Группа из следующего раунда" label-for="group_id_by_next_round">
        <b-form-select v-model="formModel.next_group_id" id="group_id_by_next_round" :options="groupsForSelect.map((g) => ({ text: g.name, value: g.id }))" />
      </b-form-group>
      <b-alert v-if="errorText" show variant="danger">{{ errorText }}</b-alert>
      <div class="form-footer">
        <b-button :disabled="isLoadingSaveForm" type="submit" variant="primary">
          {{ isLoadingSaveForm ? 'Сохраняем...' : 'Сохранить' }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { DatePicker } from 'v-calendar';
import api from "@/api/api";

export default {
  name: "AddGroupTournament",
  components: {
    DatePicker
  },
  props: {
    params: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapState('moduleNewTournament', ['selectedRoundId']),
    ...mapGetters('moduleNewTournament', ['selectedStage', 'formatBySelectedStage'])
  },
  data() {
    return {
      isLoadingSaveForm: false,
      errorText: '',
      formModel: {
        name: '',
        ...(this.formatBySelectedStage !== 'double' && { slot_count: null }),
        game_time: '',
        status: 0,
        ...(this.selectedStage?.format_id === 2 && { next_group_id: 0 }),
        approve_start_time: '',
        approve_end_time: ''
      },
      statuses: [
        {
          text: 'Игра не начата',
          value: 0
        },
        {
          text: 'Игра уже идет',
          value: 1
        },
        {
          text: 'Игра окончена',
          value: 2
        }
      ],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm',
      },
      modelConfigForConfirmTime: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm:ss',
      },
      groupsForSelect: []
    }
  },
  methods: {
    async submitForm(event) {
      try {
        event.preventDefault()
        this.isLoadingSaveForm = true
        this.errorText = null
        await this.$store.dispatch('moduleNewTournament/createGroup', {
          ...this.formModel,
          stage_id: this.params.stage.id,
          ...(this.selectedRoundId && { round_id: this.selectedRoundId })
        })
        await this.$store.dispatch('moduleNewTournament/getTournament', {
          id: this.$route.params.standingId
        })
        await this.$emit('submit')
        await this.$emit('closeModal')
      } catch (e) {
        const message = e.response.data.message
        let errors = ''
        if (e.response.data.errors) errors = Object.entries(e.response.data.errors).map(([ name, values ]) => `${ name }: ${ values.join(', ') }`).join('. ')
        this.errorText = `${ message }; ${ errors }.`
      } finally {
        this.isLoadingSaveForm = false
      }
    },
    async getGroupsFromNextRound() {
      const { round } = (await api.tournamentStage.getGroupsFromNextRound(this.selectedRoundId)).data
      this.groupsForSelect = round.groups || []
    },
    setApproveStartTime() {
      this.formModel.approve_end_time = this.formModel.game_time;
    }
  },
  created() {
    if (this.selectedStage?.format_id === 2) {
      this.getGroupsFromNextRound()
    }
    this.$watch('formModel.game_time', this.setApproveStartTime);

  }
}
</script>

<style scoped lang="scss">
.add-group-tournament-wrapper {
  .confirm-time {
    display: flex;
    gap: 10px
  }
  .form-group {
    width: 100%;
  }
  .grid-mini-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;

    &.one-column {
      grid-template-columns: 100%;
    }

    @media screen and (max-width: 800px) {
      display: block;
    }

    & > * {
      align-self: center;
      justify-self: center;
    }
  }
  .form-footer {
    border-top: 1px solid #dee2e6;
    text-align: right;
    padding-top: 15px;
  }
}
</style>