<template>
  <div>
    <div class="container-fluid">
      <div class="pt-4">
        <Breadcrumbs main="Рейтинг MLBB" title="Турниры - рейтинг mlbb" />

        <b-card class="rounded-0">
          <!-- Loader, пока идёт загрузка -->
          <div v-if="loading" class="d-flex justify-content-center align-items-center text-center py-3" style="height: 500px;">
            <b-spinner label="Загрузка..." type="grow" variant="primary"></b-spinner>
          </div>

          <!-- Таблица, если уже не грузится и есть данные -->
          <b-table
              v-else-if="tournaments.length"
              responsive
              show-empty
              stacked="md"
              :striped="true"
              :bordered="true"
              :fields="fields"
              :items="tournaments"
              class="mb-5"
          >
            <template v-slot:cell(tournament)="{ item }">
              {{ item.name }}
            </template>

            <template v-slot:cell(end_time)="{ item }">
              {{ formatEndTime(item.end_time) }}
            </template>

            <template v-slot:cell(action)="{ item }">
              <div class="align-items-center">
                <router-link :to="{ name: 'rating_mlbb_tournaments_info', params: { id: item.id } }">
                  <span>
                    <b-btn variant="primary" class="mr-2 p-1 px-2 mb-1">Посмотреть</b-btn>
                  </span>
                </router-link>
              </div>
            </template>
          </b-table>

          <!-- Заглушка, если не загрузка, но данных нет -->
          <div v-else>
            Нет данных или загрузка...
          </div>

          <!-- Пагинация -->
          <b-pagination
              v-if="pagination.total > 0"
              v-model="currentPage"
              :total-rows="pagination.total"
              :per-page="pagination.by || 10"
              align="center"
              @change="onPageChange"
              class="mt-2"
          ></b-pagination>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/api.js'

export default {
  name: "TournamentMlbb",
  data() {
    return {
      // Список турниров
      tournaments: [],
      // Объект пагинации
      pagination: {},
      // Поля таблицы
      fields: [
        { key: 'id', label: 'id', sortable: true },
        { key: 'name', label: 'Турнир', sortable: true },
        { key: 'end_time', label: 'Дата завершения', sortable: true },
        { key: 'action', label: 'Действия', sortable: false },
      ],
      // Текущая страница пагинации
      currentPage: 1,
      // Флаг загрузки
      loading: false
    }
  },
  created() {
    // Считываем page из query, если есть
    const pageFromQuery = parseInt(this.$route.query.page, 10)
    if (!isNaN(pageFromQuery) && pageFromQuery > 0) {
      this.currentPage = pageFromQuery
    }

    // Загружаем данные при инициализации компонента
    this.loadData()
  },
  watch: {
    // Следим за изменением query‐параметра "page" в маршруте
    '$route.query.page'(newVal) {
      const pageNumber = parseInt(newVal, 10)
      if (!isNaN(pageNumber) && pageNumber !== this.currentPage) {
        this.currentPage = pageNumber
        this.loadData()
      }
    }
  },
  methods: {
    async loadData() {
      // Устанавливаем флаг загрузки
      this.loading = true

      try {
        const response = await api.tournament.index({
          'filter[game_id]': 3,
          page: this.currentPage
        })

        this.tournaments = response.data?.tournaments || []
        this.pagination = response.data?.pagination || {}
      } catch (error) {
        console.error('Ошибка при получении данных:', error)
      } finally {
        // Сбрасываем флаг загрузки
        this.loading = false
      }
    },

    formatEndTime(endTime) {
      if (!endTime) return '---'
      const date = new Date(endTime)
      return date.toISOString().split('T')[0]
    },

    // При смене страницы пользователь кликает на пагинацию
    onPageChange(page) {
      // Обновляем query-параметры (обновление URL при переключении страницы)
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page
        }
      })
    }
  }
}
</script>

