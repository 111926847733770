<template>
  <div>
    <div class="container-fluid">
      <Breadcrumbs main="Рейтинг" title="История рейтинга команд турнира"/>
      <b-card>
        <div>
          <b-form>
            <div v-if="isLoading" class="loading-wrapper">
              <b-spinner class="spinner"></b-spinner>
            </div>
            <div v-else>
              <b-table
                  responsive
                  show-empty
                  stacked="md"
                  :striped="striped"
                  :bordered="bordered"
                  :fields="fields"
                  :items="tournamentTeamsRating"
                  :current-page="0"
                  :per-page="10"
                  class="mb-5"
              >
                <!-- Колонка ID команды -->
                <template v-slot:cell(id)="{ item }">
                  {{ item?.tournament_team?.id }}
                </template>

                <!-- Колонка Название команды -->
                <template v-slot:cell(team)="{ item }">
                  {{ item.name }}
                </template>

                <!-- Колонка Рейтинг команды: b-input + b-spinner (при загрузке) -->
                <template v-slot:cell(rating)="{ item }">
                  <div class="d-flex align-items-center">
                    <b-input
                        class="w-50"
                        :value="item.rating"
                        @input="value => onRatingInput(item?.tournament_team?.id, value)"
                    />
                    <!-- Отображаем мини-спиннер, если идёт обновление -->
                    <b-spinner small class="ml-2" v-if="ratingLoading[item?.tournament_team?.id]"></b-spinner>
                  </div>
                </template>

                <!-- Колонка кнопки "Подробнее" -->
                <template #cell(details)="data">
                  <div class="d-flex justify-content-center">
                    <b-button
                        size="lg"
                        @click="showTeamRatingsDetails(data)"
                        class="px-1 py-0 d-flex"
                        variant="none"
                    >
                      <i class="fa fa-arrow-circle-o-down"></i>
                    </b-button>
                  </div>
                </template>

                <!-- Детали команды (история рейтинга) -->
                <template #row-details="data">
                  <div class="card p-0 m-0">
                    <b-table
                        :items="tournamentTeamShowRating"
                        :fields="oneTeamRatingsFields"
                        striped
                        hover
                        responsive
                        show-empty
                    >
                      <template #cell(stage)="cellData">
                        {{ cellData.item?.ratingStage.name }}
                      </template>
                      <template #cell(group)="cellData">
                        {{ cellData.item?.ratingGroup.name }}
                      </template>
                      <template #cell(coeff_rating)="cellData">
                        {{ cellData.item?.ratingStage.rating_coefficient }}
                      </template>
                      <template #cell(coeff_personal_rating)="cellData">
                        {{ cellData.item?.ratingStage.player_rating_coefficient }}
                      </template>
                      <template #cell(matches_count)="cellData">
                        {{ cellData.item?.ratingStage.match_count }}
                      </template>
                      <template #cell(point)="cellData">
                        {{ cellData.item?.point }}
                      </template>
                      <template #cell(date)="cellData">
                        {{ cellData.item.created_at }}
                      </template>
                    </b-table>
                  </div>
                </template>
              </b-table>

              <!-- Пагинация -->
              <b-col md="6" class="p-0">
                <b-pagination
                    @change="handlePageChange"
                    v-model="tournamentTeamsRatingCurrentPage"
                    :total-rows="tournamentTeamsRatingTotal"
                    :per-page="10"
                    class="my-0"
                />
              </b-col>
            </div>
          </b-form>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { PaginationHelperMixin } from "@/mixins/Helpers/PaginationHelperMixin";
import { TournamentsRatingAdapterMixin } from "@/mixins/Rating/TournamentsRatingAdapterMixin";
import api from "@/api/api.js";

// Простая функция debounce
function debounce(fn, delay = 500) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
}

export default {
  name: "TournamentTeamsRatingInfo",
  mixins: [PaginationHelperMixin, TournamentsRatingAdapterMixin],

  data() {
    return {
      newTeamsRating: [],
      pageKey: "tournamentTeamsRatingPageNumber",
      fields: [
        { key: "id", label: "id", sortable: true },
        { key: "team", label: "Команда", sortable: true },
        { key: "rating", label: "Общий рейтинг", sortable: true },
        { key: "details", label: "Подробнее", sortable: true }
      ],
      oneTeamRatingsFields: [
        { key: "id", label: "id", sortable: true },
        { key: "stage", label: "Стадия", sortable: true },
        { key: "group", label: "Группа", sortable: true },
        { key: "coeff_rating", label: "Коэфф. рейтинга", sortable: true },
        { key: "coeff_personal_rating", label: "Коэфф. личного рейтинга", sortable: true },
        { key: "matches_count", label: "Кол-во матчей", sortable: true },
        { key: "point", label: "Рейтинг", sortable: true },
        { key: "date", label: "Дата", sortable: true }
      ],
      ratingValue: null,
      striped: true,
      bordered: true,
      isLoading: false,

      // Храним состояние загрузки для конкретной команды,
      // чтобы показывать/скрывать спиннер на нужном поле
      ratingLoading: {}
    };
  },

  created() {
    this.isLoading = true;

    // Сохраняем текущую страницу (если нужно для пагинации)
    this.recordPageToStorage(this.tournamentTeamsRatingCurrentPage, this.pageKey);

    // Загружаем данные о командах и их рейтингах
    this.grabTournamentTeamsRating({
      pageNumber: this.getCurrentPageNumber(this.pageKey),
      tournId: this.$route.params.id
    }).then(() => {
      this.isLoading = false;
    });
  },

  methods: {
    // Пагинация
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey);
      this.grabTournamentTeamsRating({
        pageNumber: pageNumber,
        tournId: this.$route.params.id
      });
    },

    onRatingInput(tournamentTeamId, value) {
      // Здесь value — это сразу новое значение из поля ввода
      // Если вы используете debounce, то вызываете debounced метод
      this.$set(this.ratingLoading, tournamentTeamId, true);
      this.debouncedAddTournamentTeamsRating(tournamentTeamId, value);
    },

    debouncedAddTournamentTeamsRating: debounce(function(tournamentTeamId, value) {
      this.addTournamentTeamsRating(tournamentTeamId, value);
    }, 1000),

    async addTournamentTeamsRating(tournamentTeamId, pointsValue) {
      try {
        const response = await api.tournamentTeams.addPoints(String(tournamentTeamId), {
          points: pointsValue
        });
        console.log(response);
      } catch (error) {
        console.error("Ошибка:", error);
      } finally {
        this.$set(this.ratingLoading, tournamentTeamId, false);
      }
    },

    // Показать историю рейтинга по конкретной команде
    showTeamRatingsDetails(data) {
      this.showTournamentTeamInfoRating({
        tournId: this.$route.params.id,
        teamId: data.item.id
      });
    },
  }
};
</script>

<style scoped>
.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
.ml-2 {
  margin-left: 0.5rem;
}
</style>
