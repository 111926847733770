<template>
  <div>
    <Breadcrumbs main="Турниры" title="Список турниров"/>
    <Modal
        name="DeleteTournament"
        title="Удаление турнира"
        v-model="isShowDeleteTournamentModal"
        :params="{ id: selectedTournamentId }"
    />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div
                  class="d-flex flex-wrap justify-content-between align-items-center"
              >
                <div class="d-flex align-items-start">
                  <div>
                    <SearchInput
                        v-model="searchForTournamentsModel"
                        @search="getFilteredTournaments()"
                        :placeholder-name="'Поиск турниров'"
                    />

                    <div class="notification-for-search">
                      Поиск по id и названию турнира
                    </div>
                  </div>

                  <b-dropdown
                      variant="primary"
                      text="Фильтр по типу"
                      class="ml-3"
                  >
                    <b-dropdown-item @click="handlerClearQuery()"
                    >Все
                    </b-dropdown-item
                    >
                    <b-dropdown-item
                        @click="setFilterToQuery(item.value)"
                        v-for="item in variations"
                        :key="item.value"
                    >{{ item.text }}
                    </b-dropdown-item
                    >
                  </b-dropdown>
                </div>
                <b-btn v-if="isDev" @click="generateNewTournament" class="mt-2"
                >Сгенерировать Турнир
                </b-btn
                >
              </div>
              <div
                  class="d-flex flex-wrap justify-content-between align-items-center"
              >
                <router-link to="create_tournament" class="btn btn-success mt-2"
                >Создать турнир
                </router-link
                >
                <div class="mt-2">
                  <b-dropdown
                      variant="primary"
                      id="dropdown-1"
                      text="Дисциплина"
                  >
                    <b-dropdown-item @click="getAllTournaments()">
                      <div class="text-dark">Все</div>
                    </b-dropdown-item>
                    <b-dropdown-item
                        v-for="(game, index) in games"
                        :key="index + 'gameId-' + game.value"
                        @click="() => {
                        // getTournamentsByGame(game.value)
                        setFilterToQueryByGame(game.value)
                      }"
                        variant="primary"
                    >
                      <div class="text-dark">
                        {{ game.text }}
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
            <div v-if="isLoadingTournaments" class="loading-wrapper">
              <b-spinner class="spinner"></b-spinner>
            </div>
            <div v-else class="card-body">
              <div class="">
                <b-table
                    responsive
                    ref="table"
                    show-empty
                    stacked="md"
                    :striped="striped"
                    :bordered="bordered"
                    :items="tournaments"
                    :fields="tablefields"
                    :current-page="0"
                    :per-page="10"
                    class="mb-5"
                >
                  <template v-slot:cell(formats)="{ item }">
                    {{ item.format.name }}
                  </template>
                  <template v-slot:cell(status)="{ item }">
                    {{ item.status.code }}
                  </template>
                  <template v-slot:cell(percentage)="{ item }">
                    {{
                      calculateFillPercentage(
                          item.slot_count,
                          item.accepted_entries_count
                      )
                    }}%
                  </template>
                  <template v-slot:cell(entries_member_count)="{ item }">
                    <div>
                      <span class="text-success">
                        {{ item.accepted_entries_count }}/
                      </span>
                      <span class="text-info">
                        {{ item.awaited_entries_count || 0 }}/
                      </span>
                      <span class="text-danger">
                        {{ item.rejected_entries_count }}
                      </span>
                    </div>
                  </template>
                  <template v-slot:cell(action)="{ item }">
                    <div class="actions mb-2">
                      <router-link
                          :to="{
                          name: 'tournament_edit',
                          params: { id: item.id },
                        }"
                      >
                        <b-btn
                            variant="primary"
                            class="action-button"
                            v-b-tooltip.hover.top="'Редактировать'"
                        >
                          <i class="fa fa-edit"></i>
                        </b-btn>
                      </router-link>
                      <router-link
                          :to="{
                          name: 'tournament_standings',
                          params: { standingId: item.id },
                        }"
                      >
                        <b-btn
                            variant="primary"
                            class="action-button"
                            v-b-tooltip.hover.top="'Сетка'"
                        >
                          <img :src="gridIcon" alt="" width="13" height="13"/>
                        </b-btn>
                      </router-link>
                      <router-link
                          :to="{
                          name: 'tournament_teams',
                          params: { id: item.id },
                        }"
                      >
                        <b-btn
                            variant="primary"
                            class="action-button"
                            v-b-tooltip.hover.top="'Заявки'"
                        >
                          <i class="fa fa-users"></i>
                        </b-btn>
                      </router-link>
                      <div>
                        <b-btn
                            variant="danger"
                            class="action-button"
                            @click="setTournamentId(item.id)"
                            v-b-tooltip.hover.top="'Удалить'"
                        >
                          <img
                              :src="deleteIcon"
                              alt=""
                              width="13"
                              height="13"
                          />
                        </b-btn>
                      </div>
                    </div>
                    <div
                        v-if="item?.format?.name?.toLowerCase() === 'squad'"
                        class="actions"
                    >
                      <b-btn
                          @click="calculateTeamsRatingForTournament(item.id)"
                          variant="light"
                          class="action-button"
                          v-b-tooltip.hover.top="
                          `${
                            getIconByTeamRaitingsCount(item?.team_ratings_count)
                              .text
                          }`
                        "
                      >
                        <img
                            :src="
                            getIconByTeamRaitingsCount(item?.team_ratings_count)
                              .icon
                          "
                            alt=""
                            width="13"
                            height="13"
                        />
                      </b-btn>
                      <b-btn
                          @click="recalculateTeamsRatingForTournament(item.id)"
                          variant="light"
                          :disabled="item?.team_ratings_count <= 0"
                          class="action-button"
                          v-b-tooltip.hover.top="'Перерасчет рейтинга'"
                      >
                        <img :src="recycleIcon" alt="" width="13" height="13"/>
                      </b-btn>
                    </div>
                  </template>
                </b-table>
              </div>
              {{ handlePageChange }}
              {{ currentPage }}
              {{ total }}
              <b-col md="6" class="p-0">
                <b-pagination
                    @change="handlePageChange"
                    v-model="currentPage"
                    :total-rows="total"
                    :per-page="10"
                    class="my-0"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>
<script>
import {TournamentAdapterMixin} from "../../mixins/Tournament/TournamentAdapterMixin";
import {ModalAdapterMixin} from "@/mixins/modal/ModalAdapterMixin";
import {PaginationHelperMixin} from "@/mixins/Helpers/PaginationHelperMixin";
import Modal from "@/components/modals/index.vue";
import SearchInput from "@/components/SearchInput.vue";
import recycleIcon from "@/assets/icons/recycle-white.svg";
import FullStarIcon from "@/assets/icons/full-star.svg";
import EmptyStarIcon from "@/assets/icons/empty-star.svg";
import gridIcon from "@/assets/icons/grid-white.svg";
import deleteIcon from "@/assets/icons/delete-white.svg";
import api from "@/api/api";
import Vue from "vue";

export default {
  name: "TournamentsList",
  components: {SearchInput, Modal},
  mixins: [TournamentAdapterMixin, ModalAdapterMixin, PaginationHelperMixin],
  mounted() {
    try {
      (async () => {
        this.isLoadingTournaments = true
        this.recordPageToStorage(this.currentPage, this.pageKey)
        await this.indexTournaments({pageNumber: this.getCurrentPageNumber(this.pageKey)})
        await this.indexFormat({})
        await this.indexTimeZones()
        await this.getCountriesQuery()
        await this.grabGames()
        await this.getGameIds()
        await this.grabVariations()

        if (this.$route.query.variation_id) {
          await this.getTournamentsByVariation(this.$route.query.variation_id)
        }
        if (this.$route.query.game) {
          await this.getTournamentsByGame(this.$route.query.game)
        }

      })()
    } finally {
      localStorage.removeItem('selectedStageId')
      localStorage.removeItem('selectedGroupId')
      this.isLoadingTournaments = false
    }
  },
  data() {
    return {
      recycleIcon,
      gridIcon,
      deleteIcon,
      pageKey: "pageNumberTournamentsList",
      tablefields: [
        {key: "id", label: "id", sortable: true},
        {key: "name", label: "Турнир", sortable: true},
        {key: "formats", label: "формат", sortable: true},
        {key: "slot_count", label: "кол-во слотов", sortable: true},
        {
          key: "entries_member_count",
          label: "принятые/ожидают/отклонены",
          sortable: false,
        },
        {key: "percentage", label: "Добор", sortable: true},
        {key: "status", label: "статус", sortable: true},
        // {key: 'rating', label: 'рейтинг', sortable: true,},
        {key: "game.name", label: "игра", sortable: true},
        {key: "organizer.name", label: "организатор", sortable: true},
        {key: "start_time", label: "старт турнира", sortable: true},
        {key: "action", label: "Действия", sortable: false},
      ],
      totalRows: 10,
      striped: true,
      bordered: true,
      isShowDeleteTournamentModal: false,
      selectedTournamentId: null,
      gameIds: [],
    };
  },
  methods: {
    async recalculateTeamsRatingForTournament(tournamentId) {
      try {
        await api.tournament.recalculateRating(tournamentId);
        Vue.toasted.success("Перерасчет запущен", {
          position: "top-right",
          duration: 3000,
        });
      } catch (error) {
        Vue.toasted.error(
            error.response.data.message || "Перерасчет запустить не удалось",
            {
              position: "top-right",
              duration: 3000,
            }
        );
      }
    },
    async getAllTournaments() {
      try {
        const param = {
          pageNumber: this.getCurrentPageNumber(this.pageKey),
        }
        this.isLoadingTournaments = true;
        this.recordPageToStorage(this.currentPage, this.pageKey);

        await this.indexTournaments(param);
        await this.indexFormat({});
        await this.indexTimeZones();
        await this.getCountriesQuery();
        await this.grabGames();
        await this.getGameIds();

      } finally {
        localStorage.removeItem("selectedStageId");
        localStorage.removeItem("selectedGroupId");
        this.isLoadingTournaments = false;
      }

    },
    getGameIds() {
      this.gameIds = this.games.map((game) => game.value);
    },
    calculateFillPercentage(slotCount, acceptedEntriesCount) {
      if (!slotCount || slotCount === 0) return 0;
      return ((acceptedEntriesCount / slotCount) * 100).toFixed(2);
    },
    handlePageChange(pageNumber) {
      this.recordPageToStorage(pageNumber, this.pageKey);
      this.indexTournaments({
        pageNumber: pageNumber,
        ...(this.searchForTournamentsModel && {
          search: this.searchForTournamentsModel,
        }),
          gameId: this.selectedGameId,
      });
    },
    async handlerClearQuery() {
      this.getAllTournaments()
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({path: this.$route.path});
      }
    },
    getFilteredTournaments() {
      this.isLoadingTournaments = true;
      this.indexTournaments({
        search: this.searchForTournamentsModel,
        ...(this.gameIds.includes(this.selectedGameId) && {
          gameId: this.selectedGameId,
        }),
      }).then(() => {
        this.isLoadingTournaments = false;
      });
    },
    setTournamentId(id) {
      this.selectedTournamentId = id;
      this.isShowDeleteTournamentModal = true;
    },
    getIconByTeamRaitingsCount(count) {
      if (count > 0) return {icon: FullStarIcon, text: "Рейтинг расчитан"};
      return {icon: EmptyStarIcon, text: "Расчет рейтинга"};
    },

    setFilterToQuery(variationId) {
      const currentQuery = {...this.$route.query};

      // Удаляем game из query, т.к. устанавливаем variation_id
      delete currentQuery.game;

      // Если variation_id уже совпадает с текущим, не делаем навигацию
      if (currentQuery.variation_id === String(variationId)) {
        return; // Прекращаем выполнение, чтобы избежать ошибки
      }

      // Навигация, если параметры изменились
      this.$router.push({
        query: {...currentQuery, variation_id: variationId},
      });
    },

    setFilterToQueryByGame(game) {
      const currentQuery = {...this.$route.query};
      // Удаляем variation_id из query, т.к. устанавливаем game
      delete currentQuery.variation_id;

      // Если game уже совпадает с текущим, не делаем навигацию
      if (currentQuery.game === String(game)) {
        return; // Прекращаем выполнение, чтобы избежать ошибки
      }

      // Навигация, если параметры изменились
      this.$router.push({
        query: {...currentQuery, game},
      });
    }

  },
  watch: {
    // Наблюдатель за изменением query параметра variation_id
    "$route.query.variation_id": {
      async handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal) {
          await this.getTournamentsByVariation(newVal);
          // Вызываем метод для получения данных
        }
      },
      immediate: true, // Выполнить сразу после монтирования компонента
    },
    "$route.query.game": {
      async handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal) {
          this.getTournamentsByGame(newVal);
        }
      },
      immediate: true, // Выполнить сразу после монтирования компонента
    }
  },
  computed: {
    isDev() {
      return process.env.NODE_ENV === "development";
    },
  }
};
</script>
<style scoped>
.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 7px;

  @media (max-width: 770px) {
    display: flex;
  }
}

.action-button {
  padding: 8px 8px;
}
</style>
